@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@100;200;300;400;500;600;800;900&display=swap');

@font-face {
  font-family: 'Helvetica Neue';
  src: url("../fonts/5622d11c-c6b8-476c-9d17-995348bd8400.woff2") format("woff2"),
    url("../fonts/d8a5d085-4356-4bc9-b496-51439ecdcd04.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

:root {
  font-size: 16px;
}

*:focus {
  border-color: #7952b3;
  box-shadow: 0 0 0 3px rgba(121,82,179,0.25);
}

html {
  background-color: #E5E5E5;
}

body {
  font-family: 'Archivo' !important;
}

::-webkit-scrollbar-track {
  background-color: #fff;
  font-family: 'Archivo', sans-serif;
}


::-webkit-scrollbar-track {
  background-color: #fff;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: $borderColor;
  border: 2px solid $borderColor;
  border-radius: 20px;
  margin-right: 10px;
}

// html {
//   scroll-behavior: smooth;
// }

// .fnt-14 {
//   font-size: 14px;
//   color: $midgrey;
// }

.dbdigit .cntWrap {
  left: -55px;
}

.table--fixed {
  table-layout: fixed;
}

// Custom Select
.css-2b097c-container {
  .css-1wa3eu0-placeholder {
    color: $lightGrey;
  }

  .css-ig5d4d-menu {
    width: 130px;
    border: 1.5px solid $borderColor;
    box-shadow: none;

    div {
      color: $labelColor;
      color: #666;
    }
  }

  &.status-box {
    .css-79tn2m-control {
      height: 46px !important;
      border-color: #ccc;
    }
  }

  .css-1pahdxg-control,
  .css-yk16xz-control {
    border-width: 1.5px;
    border-radius: 0.13rem;
    box-shadow: none;

    .css-tlfecz-indicatorContainer {
      color: $lightGrey;
      color: #1e1e1e;
    }
  }

  .css-yk16xz-control {
    border-color: $borderColor;
  }

  .css-1pahdxg-control {
    border-color: $borderColor;

    &:hover {
      border-color: $borderColor;
    }
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }

  .css-1gtu0rj-indicatorContainer,
  .css-tlfecz-indicatorContainer {
    &>svg {
      display: none;
    }

    &:after {
      content: "\e90a";
      speak: none;
      font-family: "icomoon" !important;
      font-size: 0.63rem;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  .css-1gtu0rj-indicatorContainer {
    &:after {
      color: $midgrey;
    }
  }

  .css-26l3qy-menu {
    margin-top: 0;
    border-radius: 0;
    border: 1.5px solid $borderColor;
    border-top: none;
    margin-top: -3px;
    box-shadow: none;

    .css-4ljt47-MenuList {
      padding-top: 0;
      padding-bottom: 0;
      border-color: $borderColor;
      font-size: 0.88rem;

      .css-1n7v3ny-option,
      .css-9gakcf-option,
      .css-yt9ioa-option {
        font-size: 0.88rem;
        background-color: transparent;
        padding: 5px 10px;
        cursor: pointer;

        &:hover {
          color: $lightGrey;
        }
      }

      .css-1n7v3ny-option,
      .css-yt9ioa-option {
        color: $midgrey;
        transition: all ease-in-out 350ms;
      }

      .css-9gakcf-option {
        color: $lightGrey;
      }
    }
  }

  &.select-border-pimary {

    .css-1pahdxg-control,
    .css-yk16xz-control {
      border-color: #6f5dd8;
      background-color: transparent;

      &:hover {
        border-color: #6f5dd8;
      }
    }

    .css-26l3qy-menu option {
      border-color: #6f5dd8;
      white-space: initial;
      word-break: none;
    }
  }
}

.modal-content h3 {
  font-size: 1.8rem;
}

body {
  overflow-x: hidden;
  line-height: 1.3;

  :focus {
    outline: none;
  }

  .h1 {
    font-size: 2.1875rem;
  }

  .smaller {
    font-size: 12px;
  }

  .smallText {
    font-size: 15px;
  }

  .fontSize {
    font-size: 18px;
  }

  .mediumText {
    font-size: 30px;
  }

  .subMediumText {
    font-size: 22px;
  }

  .largeText {
    font-size: 50px;
  }

  .fntWeight300 {
    font-weight: 300;
  }

  .tags-fields {
    align-items: end !important;

    // padding-left: 20px;
    .filterby {
      width: 95px;
    }
  }

  button:focus {
    outline: none;
  }

  a {
    color: $primary-color;

    &:hover {
      color: $primary-color;
      text-decoration: underline;
    }
  }

  .tags {
    text-align: left;
    padding-left: 20px;
    width: 100%;
    display: flex;
    align-items: center;

    .allTags_selected {
      position: absolute;
      bottom: -47px;
      left: 110px;
      align-items: end;
      height: 39px;
      z-index: 1;
      overflow: auto;
      flex-wrap: wrap;

      .tags_selected {
        border: 1px solid $borderColor;
        padding: 7px 10px;
        font-size: 13px;
        text-transform: uppercase;
        color: $labelColor;
        margin-right: 10px;
        background: #fff;
        margin-bottom: 10px;

        svg {
          background: transparent;
          border: 0;
          margin-left: 10px;
          font-size: 12px;
          color: $labelColor;
          cursor: pointer;
          margin-top: -4px;
        }
      }
    }

    .singleTag {

      .css-1wrkhso-control,
      .css-2b097c-container .css-1bi0jn-control {
        min-height: 37px !important;
        border: 1.5px solid $borderColor;
        border-radius: 4px;
      }

      div {
        font-size: 14px;
        justify-content: flex-start;
      }
    }

    .dropdown-item.active,
    .dropdown-item:active {
      color: #fff;
      text-decoration: none;
      background-color: transparent;
    }

    .dropdown-item {
      padding: 4px 17px;
    }

    .css-1hb7zxy-IndicatorsContainer {
      color: #adadc9;
    }

    .css-1wa3eu0-placeholder {
      width: 78%;
      text-align: center;
      text-transform: uppercase;
    }

    .w-20 {
      max-width: 100px;
    }

    .filterBy {
      padding: 12px;
    }

    .css-1uccc91-singleValue {
      display: flex;
      align-items: center;
      width: 80%;
      justify-content: center;
    }

    .dropdown-menu {
      min-width: 20rem;
      max-height: 260px;
      overflow: auto;
      transform: none !important;
      top: 40px !important;
      border: 1.5px solid $borderColor;

      .form-group {
        margin-bottom: 0;
      }

      .checkbox {
        padding: 6px 0;
      }
    }

    .dropdown {
      display: inline-block;
      margin-left: 12px;

      &:nth-child(1) {
        margin-left: 0 !important;
      }

      &.show {
        button {
          border: 0;
          padding: 8px 15px;
          line-height: initial;

          &:focus {
            outline: none;
          }

          &[aria-expanded="true"] {
            background: $primary-color;
            color: #fff;
            border: 1.5px solid $primary-color;
          }

          &[aria-expanded="false"] {
            border: 1.5px solid $labelColor;
          }
        }
      }

      button {
        padding: 8px 15px;
        line-height: initial;
        border: 1.5px solid $borderColor;
        background: transparent;
        border-radius: 3px;
        font-size: 14px;
        color: $labelColor;
        text-transform: uppercase;

        &[aria-expanded="true"] {
          background: $primary-color;
          color: #fff;
          border-color: $primary-color;
        }
      }
    }

    .multi-select .dropdown-content {
      width: 290px !important;
    }

    .css-yk16xz-control {
      border: 1.5px solid $borderColor;
      cursor: pointer;

      &:hover {
        border: 1.5px solid $borderColor !important;
      }
    }

    .css-1pahdxg-control {
      border-color: $primary-color !important;
    }
  }

  .mt-6 {
    margin-top: 4rem;
  }

  .checklist-tag {
    display: flex;
    position: relative;
    flex-wrap: wrap;

    p {
      padding: 5px 4px;
      border: 1.5px solid #eee;
      margin: 10px 5px 0 2px;
      background: #fff;
      z-index: 1;
      text-transform: uppercase;
      font-size: 14px;
      color: $labelColor;

      button {
        border: 0;
        background: transparent;
        padding-left: 12px;
        color: $labelColor;
      }
    }
  }

  input:-internal-autofill-selected {
    background-color: #f18f3f !important;
  }

  .purple_link {
    color: $primary-color;
  }

  .back-link {
    svg {
      vertical-align: baseline;
      margin-right: 3px;
    }

    &:hover {
      svg {
        stroke: $primary-color !important;
        fill: $primary-color !important;
        fill: $primary-color !important;
      }
    }
  }

  .input-icon-addon {
    top: 0;
    width: 30px;
    align-items: center;
    justify-content: center;
    margin-top: -1px;
  }

  .tags-fields {
    align-items: center;

    // padding-left: 20px;
    .dropdown-heading-dropdown-arrow {
      background: #fff;
    }

    span {
      font-size: 20px;
    }

    .item-renderer {
      span {
        font-size: 13px;
        padding: 4px 5px 4px 20px !important;
      }
    }
  }

  .thanksModal {
    h2 {
      margin: 25px 0;
      font-size: 22px;
      line-height: 1.4;
      // white-space: break-spaces;
      // width: 200px;
    }

    .modal-dialog {
      width: auto !important;
    }


  }

  ul.react-tabs__tab-list {
    position: relative;
  }

  ul.react-tabs__tab-list .solidBtn__blue {
    position: absolute;
    right: 0;
    width: auto;
    bottom: 10px;
    text-transform: capitalize;
  }

  .detailsCount {
    color: $midgrey;
    font-size: 16px;
  }

  @media (min-width: 1366px) {
    .container.CustomWidth {
      //max-width: 1250px !important;
    }
  }

  @media (min-width: 1440px) {
    .container.CustomWidth {
      //max-width: 1350px !important;
      // min-width: 94%;
    }
  }

  @media (max-width: 1380px) {
    .container.CustomWidth {
      .card .card-header .input-icon {
        width: 270px;
      }
    }
  }
}

.headercustom {
  background-position: center center;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #fff;
  background-size: cover;
  border-bottom: 1px solid $purpleColor;
}

// sorting icons
.sortWrap {
  position: relative;

  svg {
    color: $labelColor2;
    padding-left: 3px;
    width: 15px !important;
  }
}

th .sortWrap .icon-back-arrow1 {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: -12px;
  transform: translateY(-50%) rotate(-90deg);
  transform-origin: center center;
}

th .sortWrap [class^="icon-"],
th .sortWrap [class*=" icon-"] {
  font-size: 9px;
}

th .sortWrap--up {
  svg {
    color: $primary-color;
  }

  .icon-back-arrow1 {
    transform: translateY(-50%) rotate(90deg);

    &:before {
      display: inline-block;
    }
  }
}

// Feedback


.feedWrap {
  position: fixed;
  // top: 33px;
  right: 100px;
  max-width: 500px;
  padding: 0 15px;
  // transform: translateX(10px);
  z-index: 999;
  display: block;
  margin-top: 3px;

  @media screen and (min-width: 1200px) {
    //max-width: 1110px;
  }

  @media screen and (min-width: 1366px) {
    //max-width: 1220px;
  }

  @media screen and (min-width: 1440px) {
    // max-width: 1320px;
  }

  .feedInnerWrap {
    width: 100%;
    max-width: 400px;
    padding: 22px;
    // background: $whiteColor;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    position: fixed;
    right: 60px;
    top: 72px;
    display: none;
    transform-origin: top right;
    transition: all ease-in-out 450ms;

    button.solidBtn {
      color: #fff !important;
    }

    label {
      color: $labelColor;
      font-size: 0.875rem;
    }

    .feedInner__head {
      position: relative;

      .popClose {
        position: absolute;
        right: -10px;
        top: 0px;
        color: #000;
        font-size: 1.4rem; border: none; background-color: transparent;
      }
    }

    textarea {
      resize: none;
    }
  }

  &.active {
    .feedInnerWrap {
      top: 72px;
      display: block;
      border: solid 1px $borderColor;
      background-color: #fff;
    }
  }
}

.feedback-btn-wrap {
  position: relative;
  z-index: 8;
}

.feedWrap_btn button {
  border-radius: 5px 5px 0 0;
}

.feedBackButton.active {
  background-color: #fff !important;
  color: $primary-color !important;
  border: solid 1px $borderColor !important;
  border-bottom: 0 !important;
  border-radius: 8px 8px 0 0;
}

.feedBackButton {
  // border: solid 1px #fff !important;
}

.feedBackButton.active.btn-outline-secondary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
  border: none;
}

.feedWrap.inactive {
  display: none;
}

.feedback-header {
  z-index: 9999;
  top: 8px;
}

@media(max-width: 480px) {
  .feedback-main {
    flex-flow: row;
    width: auto;
  }

  .feedback-header {
    padding: 0 !important;

    button {
      font-size: 0;
    }

  }

  .request-header button {
    font-size: 11px;
    font-weight: 400 !important;
  }

  .feedWrap .feedInnerWrap {
    max-width: 270px;
  }
}

// React Date Picker
.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text,
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name,
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-family: archivo;
  color: $midgrey;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: $primary-color;
  color: #fff;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: rgba(98, 59, 165, 0.8);
}

.react-datepicker__day--today {
  color: $primary-color;
}

.react-datepicker {
  &-wrapper {
    width: 100%;
  }

  &__input-container {
    width: 100%;

    .form-control {
      padding-right: 2.188px;
    }

    &:after {
      position: absolute;
      right: 10px;
      top: 11px;
      font-size: 1.4rem;
      content: "\e918";
      font-family: "icomoon" !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}

.formModalWrap {
  .modal-label {
    font-size: 14px;
  }
}

.lblWrap {
  &--width {
    flex: 0 0 140px;
  }

  .modal-label {
    font-size: 14px;
    margin-bottom: 0;
  }
}

.formWrap_content {
  font-size: 15px;
}

button.btn.btn-outline-primary.ml-4.mt-2 {
  width: 40%;
}


button.btn.btn-outline-secondary.ml-4.mt-2 {
  width: 40%;
}

.submit {
  float: right;
}

// Contact form css start

.cneterForm {
  max-width: 595px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2rem;

}

.modal-contactfrom {
  .check-container .checkmark {
    top: 2px;
    width: 15px !important;
    height: 15px !important;
  }

  .modal-contactfrom .modal-dialog {
    box-shadow: none !important;
  }

  .check-container input:checked~.checkmark {
    background-color: #AD337B;
    border-color: #AD337B;
    box-shadow: 1px 0 1px rgba(97, 121, 133, 0.05);
    color: lightnen(#F18F3F, 5%);
  }

  .modal-content .checkmark,
  .radio-container .checkmark {
    border: 2px solid #623BA5;
    border-radius: 4px;
  }

  .closebuttonCust {
    position: absolute;
    margin-right: 28px;
    color: #000;
    right: 0;
    margin-top: 6px;
    background: transparent;
    padding: 0;
    height: 11px !important;
    width: 11px !important;
    text-align: center;
  }

  .modal-dialog {
    // box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.07), 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 2px 2px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.05);
    width: 491px;

    .modal-content {
      border-radius: 24px;
    }

    .modal-body {
      padding: 25px;
      border-radius: 24px;
    }

    h4 {
      font-family: 'Archivo', sans-serif;
      font-style: normal;
      font-size: 20px;
      font-weight: bold;
    }

    .checkBox label {
      color: $textColordarkGrey;
      font-size: 14px;
      line-height: 1.3;
    }

    input[type=checkbox]:checked {
      accent-color: #623ba5 !important;
    }
  }
}

.actionButton-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .submit {
    .btn {
      padding: 8px 18px;
      border-radius: 4px;
    }

    .btn-primary {

      background: $primaryTextColor;

      border-color: $primaryTextColor;
    }

    .btn-outline-primary {
      background: rgba(0, 0, 0, 0.0001);
      border: 1px solid rgba(0, 0, 0, 0.16);
      color: $textColordarkGrey;

      &:hover {
        color: $textColordarkGrey;
      }

    }

    .btn-outline-primary:not(:disabled):not(.disabled).active,
    .btn-outline-primary:not(:disabled):not(.disabled):active,
    .show>.btn-outline-primary.dropdown-toggle {
      background-color: white;
      border: solid 1px #ccc;
    }

    .btn-primary:not(:disabled):not(.disabled).active,
    .btn-primary:not(:disabled):not(.disabled):active,
    .show>.btn-primary.dropdown-toggle {
      background: white;
      border: solid 2px #623ba5;
      color: #1e1e1e;
    }
  }

}

.actionButton {
  width: 100%;
  padding: 1.9rem 0;
  border-top: 1px solid #f0f0f0;

  .submit {
    text-align: right;
    float: none;
  }
}

.contactform {
  background: $BgColorGrey;
  height: 100vh;
  display: flex;
  padding: 3rem 1.875rem;
  font-family: 'Archivo', sans-serif;
  align-items: center;
  justify-content: center;

  // .custom-margin{ max-width: 760px; margin: auto;}
  @media screen and (max-width: 767px) {
    padding: 20px;
    height: auto;
    align-items: flex-start;
  }

  p,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Archivo', sans-serif;
  }

  button.btn.btn-contained {
    border: solid 1px #ccc;
    margin-right: 10px;
    padding: 8px 18px;
    border-radius: 4px;
  }

  .btn-outline-primary:not(:disabled):not(.disabled).active,
  .btn-outline-primary:not(:disabled):not(.disabled):active,
  .show>.btn-outline-primary.dropdown-toggle {
    background-color: #fff !important;
    border: solid 2px #623ba5 !important;
    color: #1e1e1e;
  }

  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show>.btn-primary.dropdown-toggle {
    background-color: #fff !important;
    border: solid 2px #623ba5 !important;
    color: #1e1e1e;
  }

  .inputValidate {
    padding-right: 0px;

    .error {
      width: auto;
      position: static;
      left: 16px;
      margin-top: 2px;
    }
  }

  .innrFrmWrap {
    padding-left: 144px;
    padding-right: 144px;
    overflow: hidden;
  }

  .formInputWrap {
    max-width: 585px;
    margin-left: auto;
    margin-right: auto;
  }

  .LocationPrefernces .formHeightCuston {
    // height: calc(100vh - 450px);
    overflow: hidden;
    padding-bottom: 35px;
    padding-right: 10px;
  }

  // .innrFrmWrap:hover {
  //   overflow-y: scroll;
  // }

  .prefferedLoc {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    column-gap: 10px;
  }

  .prefferedLoc {
    .prefferedLoc-btn {
      width: 32%;
      height: 56px;
      background: $whiteColor;
      border: 1px solid #ccc !important;
      border-radius: 12px;
      text-align: left;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.2;
      padding-right: 30px;
      color: $textColorgryShade;

      @media screen and (max-width: 480px) {
        width: 100%;
      }

      &.prefloc-selected {
        border: 2px solid $primaryTextColor !important;
        position: relative;

        &:after {
          content: "";
          display: inline-block;
          transform: rotate(-40deg);
          height: 5.45px;
          width: 10.29px;
          border-bottom: 1px solid $primaryTextColor;
          border-left: 1px solid $primaryTextColor;
          position: absolute;
          right: 15px;
          top: -5px;
          bottom: 0;
          margin: auto;
        }
      }

      &:nth-child(odd),
      &:nth-child(even) {
        // margin-right: 8px;
      }

      &:hover {
        color: $textColorgryShade;
      }
    }

  }

  hr {
    margin-top: 22px;
    margin-bottom: 20px;
  }

  .formHeightCuston {
    .control-label {
      padding-left: 0px;
      color: $textColorgryShade;
      margin-bottom: 0;
      align-items: center;
      vertical-align: middle;
      display: flex;

    }
  }


  form {

    .control-label {
      padding-left: 0px;
      color: $textColorgryShade;
      margin-bottom: 0;
      align-items: center;
      vertical-align: middle;
      display: flex;

    }

    // .prefferedLoc {
    //   .prefferedLoc-btn {
    //     width: 48%;
    //     height: 60px;
    //     background: $whiteColor;
    //     border: 1px solid rgba(36, 36, 36, 0.16);
    //     border-radius: 12px;
    //     text-align: left;
    //     font-style: normal;
    //     font-weight: 400;
    //     font-size: 16px;
    //     color: $textColorgryShade;

    //     &.prefloc-selected {
    //       border: 1px solid $primaryTextColor;
    //       position: relative;

    //       &:after {
    //         content: "";
    //         display: inline-block;
    //         transform: rotate(-40deg);
    //         height: 5.45px;
    //         width: 10.29px;
    //         border-bottom: 1px solid $primaryTextColor;
    //         border-left: 1px solid $primaryTextColor;
    //         position: absolute;
    //         right: 28px;
    //         top: -5px;
    //         bottom: 0;
    //         margin: auto;
    //       }
    //     }

    //     &:nth-child(odd) {
    //       margin-right: 16px;
    //     }

    //     &:hover {
    //       color: $textColorgryShade;
    //     }
    //   }

    // }

    .form-group {
      margin: 0;
      padding-right: 0px;
      margin-bottom: 15px !important;

      input {
        font-weight: 400;
        font-size: 14px;
        height: 40px;
        padding: 12px 16px;
        line-height: 1.3;
        color: $textColorInput !important;
      }
    }

    .btn-outline-primary:not(:disabled):not(.disabled).active,
    .btn-outline-primary:not(:disabled):not(.disabled):active,
    .show>.btn-outline-primary.dropdown-toggle {
      color: #1e1e1e;
      background-color: #fff;
      border: solid 2px #623ba5 !important;
    }

    .btn-primary:not(:disabled):not(.disabled).active,
    .btn-primary:not(:disabled):not(.disabled):active,
    .show>.btn-primary.dropdown-toggle {
      background-color: #fff;
      border: solid 2px #623ba5 !important;
      color: #1e1e1e;
    }

    .btn-outline-primary:not(:disabled):not(.disabled).active,
    .btn-outline-primary:not(:disabled):not(.disabled):active,
    .show>.btn-outline-primary.dropdown-toggle {
      background-color: #fff !important;
      border: solid 2px #623ba5 !important;
      color: #1e1e1e;

    }

    .btn-outline-primary:not(:disabled):not(.disabled):active {
      color: #1e1e1e;
      background-color: #fff;
    }

    .btn-primary:not(:disabled):not(.disabled).active,
    .btn-primary:not(:disabled):not(.disabled):active,
    .show>.btn-primary.dropdown-toggle {
      background-color: #fff !important;
      border: solid 2px #623ba5 !important;
      color: #1e1e1e;
    }

    .css-2b097c-container .css-1bi0jn-control {
      border-color: #ccc !important;
      border-radius: 4px;
    }

    .css-2b097c-container .css-1bi0jn-control:focus {
      background-color: #fff !important;
      border: solid 2px #623ba5 !important;
      color: #1e1e1e;
    }

    button.btn.btn-contained {
      border: 1px solid #cccccc !important;
      border-radius: 4px !important;
      margin-right: 10px;
      padding: 8px 18px;
    }

    .form-control {
      border: 1px solid #cccccc;
      border-radius: 4px;

      // text-transform: capitalize;
      &:focus {
        box-shadow: none;
        /* border: 1.5px solid #F18F3F !important; */
        border: 2px solid $primaryTextColor !important;
        // border-radius: 12px !important;
      }
    }
  }

  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 2.375rem;
    line-height: 1.3;
    color: $textColorBlkshade;
    margin-bottom: 0;
  }

  h4,
  .modal-contactfrom .modal-dialog h4 {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.3;
    color: $textColorgryShade;
  }


  .borderRoundForm {
    background: $backgroundColor;
    border-radius: 32px;
    overflow: hidden;
    height: calc(100vh - 96px);

    @media screen and (max-width: 767px) {
      height: auto;
    }
  }

  .bgdarkForm {
    background-image: url(../images/backgrounddark.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    backdrop-filter: blur(3.5px);

    .Form-leftSide {
      color: $whiteColor;
      height: calc(100vh - 75px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 3.75rem 1.875rem;

      h6 {
        font-family: $font-family;
        font-style: normal;
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 22px;
      }

      .headingGroup {
        flex-grow: 1;
        padding-top: 15px;
        overflow: auto;

        h3 {
          font-style: normal;
          font-weight: 600;
          font-size: 2.375rem;
          line-height: 45px;
        }

        a {
          text-decoration: underline;
        }

        p,
        a {
          color: $whiteColor;
          font-style: normal;
          font-weight: 400;
          font-size: 1rem;
          line-height: 24px;
        }
      }

      @media screen and (max-width: 767px) {
        height: auto;
      }
    }
  }

  .submit {
    .btn-primary {
      padding: 8px 18px;
      background: $primaryTextColor;
      border-radius: 4px;
      border-color: $primaryTextColor;
    }
  }
}

.headingH4 {
  h4 {
    color: #000;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .contactform {

  .custom-margin {
    padding: 64px 25px 90px 25px
  }
}

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 100%;
  }
}

@media (max-width: 1500px) and (min-width: 1024px) {
  .contactform .bgdarkForm .Form-leftSide {
    padding: 30px !important;
    height: calc(100vh - 120px);

    .contactform .formHeightCuston {
      height: calc(100vh - 420px);
    }
  }
}

@media (min-width: 1500px) {
  .contactform {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
      max-width: 1440px;
    }
  }
}

@media (max-width: 1280px) {
  .contactform {
    .bgdarkForm {
      .Form-leftSide {
        padding: 1.875rem 0.938rem !important;

        .headingGroup h3 {
          font-size: 2.3rem;
          line-height: 1.3;
        }
      }
    }
  }
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	.contactform {
    h2{ font-size: 1.4rem;}
    .bgdarkForm {
      .Form-leftSide {
        padding: 1.875rem 0.938rem !important; height:100%;
      }
    }
  }

}

@media only screen and (min-device-width: 820px) and (max-device-width: 1024px) {

	.contactform {
    h2{ font-size: 1.4rem;}
    .bgdarkForm {
      .Form-leftSide {
        padding: 1.875rem 0.938rem !important; height: calc(100vh - 80px);
      }
    }
  }

}


.scrollbarHide>div {
  overflow-x: hidden !important;
}

.custom-margin>.Congratulation {
  width: 350px;
  margin: auto;
  text-align: center;
}

.congratsbtn {
  width: 100%;
  margin-top: 1.875rem;
}

.congrats {
  width: 100%;
  display: inline-block;
}

.Congratulation {
  margin: auto;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.Congratulation .formHeightCuston {
  width: 350px;
  height: auto;
  overflow: hidden;
}

.right-section {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 595px;

  .formHeightCuston {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
  }

  .form-horizontal {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
  }

  .formMain {
    overflow: auto;
    flex: 1;
  }

  ::-webkit-scrollbar {
    width: 3px;
    background: transparent;
  }
}

// Contact form css end

// login screen ui starts here

.login-wrapper {
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  max-width: 560px;

  input[type=checkbox],
  input[type=radio] {
    margin-left: 0;
  }

  label {
    padding-left: 20px;
    padding-top: 3px;
  }

  .btn-link {
    padding: 0;
    font-size: 16px;
  }

  .btn-secondary:hover,
  .btn-secondary:focus {
    color: $primaryTextColor;
  }

  .btn-secondary:focus,
  .btn-secondary:not(:disabled):not(.disabled):active {
    background-color: #fff;
  }

  .solidBtn__blue:hover {
    color: #fff;
  }
}

.input-group:not(.has-validation)>.form-control:not(:last-child) {
  border-radius: 4px !important;
}

.input-group-prepend {
  margin-top: 8px;
  margin-left: 10px;
}

#email,
#password {
  padding-left: 40px;
}

body .input-group .input-group-postpend {
  top: 9px;
}

.contactform form .form-group {
  margin-bottom: 15px !important;
}

.login-h2 {
  margin-bottom: 32px;
}

.loginSection p {
  text-align: center;
  font-size: 16px;
}

.content-div .form-wrapper {
  margin-top: 20px;
}

.content-div {
  height: calc(100% - 100px);
  overflow-y: auto;
}

@media(max-width: 1200px) {
  .login-wrapper {
    margin-top: 30px;
  }

  .contactform .custom-margin {
    padding: 14px 25px 90px 25px;
  }
}

@media(max-width: 1200px) {
  h3 {
    font-size: 1.2rem;
  }

  .headingGroup p {
    font-size: 0.75rem;
  }

  .login-wrapper {
    margin-top: 30px;
  }

  .contactform .custom-margin {
    padding: 14px 25px 90px 25px;
  }
}


@media (max-width: 992px) {

  .contactform {
    .bgdarkForm {
      .Form-leftSide {
        padding: 1.875rem 0.938rem !important;

        .headingGroup h3 {
          font-size: 1.2rem;
          line-height: 1.2;
        }

        p {
          font-size: 0.8rem;
        }
      }

    }
    .prefferedLoc .prefferedLoc-btn{width: 48%;}
  }
}


@media (max-width: 768px) {
  body{font-size: 1rem;}
  .container{max-width: 100%;}
  .contactform {
    padding: 3rem 1rem;
    h2{ font-size: 1.8rem;}
    .bgdarkForm {
      .Form-leftSide {
        padding: 1.875rem 0.938rem !important; height: 100%;
      }
    }
    .prefferedLoc .prefferedLoc-btn{width: 48%;}

  }

  body .mt-6{margin-top: 32px !important;}
.solidBtn, .btn, .btn-outline-primary, .btn-primary, .btn-secondary{font-size: 0.75rem;}
.pagination li a{ padding: 6px 9.3px 0  !important}
.previous > a.pagination__link, .next > a.pagination__link{ font-size: 0;}
.previous > a.pagination__link::after{content: "<"; font-size: 18px;}
.next > a.pagination__link::after{content: ">"; font-size: 18px;}
.status_box, .card-footer { font-size: 0.90rem;}
}


@media (max-width: 600px) {
  .container{max-width: 580px;}
  .pagination {
    float: left !important;
  }

  .login-wrapper {
    margin-top: 30px;
  }

  .contactform {

    h2 {
      font-size: 1.6rem;
    }

    h4 {
      font-size: 1.2rem;
    }

    .prefferedLoc .prefferedLoc-btn {
      width: 40%;
    }

    hr {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 576px){
  .container, .container-sm {
    max-width: 550px;
}

}

@media(max-width: 480px) {
  #app {
    margin: 0 20px;
  }


  .login-wrapper {
    margin-top: 30px !important;

    label,
    .btn-link {
      font-size: 0.80rem;
    }

    .checkBox {
      line-height: 22px;
    }
  }

  .contactform {
    padding: 20px 0;

    .bgdarkForm .Form-leftSide .headingGroup h3 {
      font-size: 1.6rem;
    }

    h2 {
      font-size: 1.6rem;
    }
  }

  // .contactform,
  // .container,
  // .borderRoundForm {
  //   min-height: 98vh;
  // }


  .userdetails .col-8 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .userdetails .col-4 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  h4 {
    font-size: 1.2rem !important;
  }

  .linkedin-btn {
    font-size: 0 !important;
  }

  .request-info svg,
  .linkedin-btn svg {
    margin-right: 0;
  }
}

.inactive-filter {
  opacity: 30%;
}

@import "../abstracts/mixins";

.btn,
button {
  font-weight: 500;
  font-size: 0.9rem;
  /*padding: .275rem .75rem;*/
}

.btn-primary {
  margin-right: 8px;
  border-radius: $borderRadius; color:#fff;
}

.btn-primary,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  background-color: $primary-color;
  border-color: $primary-color;
}

//.btn-primary:hover{ background: rgba(98, 59, 165, 0.8); opacity: 1; border:solid 1px rgba(98, 59, 165, 0.8);}
.btn-primary:hover {
  background: #6e47b1;
  opacity: 1;
  border: solid 1px #6e47b1;
}



.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
  color: $whiteColor;
  background-color: $primary-color;
  border-color: $primary-color;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  color: $whiteColor;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #f2eaff;
}

.btn-primary.focus,
.btn-primary:focus {
  background-color: rgba(98, 59, 165, 0.8);
  border-color: #6e47b1;
}

.btn-outline-secondary {
  border-radius: 8px;
  border-color: rgba(98, 59, 165, 0.2);
  color: $primaryTextColor;
  font-size: 14px;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show>.btn-outline-secondary.dropdown-toggle {
  border: solid 1px $primary-color;
}

.btn-outline-secondary:hover {
  background-color: white;
  color: $primaryTextColor;
  border-color: rgba(98, 59, 165, 0.5);
  border-width: 1px;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active {
  background-color: #fff;
  color: #000000;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus {
  border: 1px solid rgba(98, 59, 165, 0.26);
  box-shadow: none;
}

button:focus {
  outline: none;
}

.btn-min100 {
  width: auto !important;
  min-width: 100px !important;
}

.btn-min150 {
  width: auto !important;
  min-width: 150px !important;
}

.btn-min200 {
  width: auto !important;
  min-width: 200px !important;
}

.btn-mx280 {
  width: 100% !important;
  max-width: 280px !important;
}

.btn-mx330 {
  width: 100% !important;
  max-width: 330px !important;
}

.solidBtn {
  // @include solidBtn($transparent, $whiteColor, $transparent);
  padding: .375rem .75rem;
  line-height: 1.5;
  border-radius: 8px;
  font-size: 0.9rem;
  cursor: pointer; color:#fff;

  &:focus {
    // opacity: 0.6;
    background: $primary-color;
    border-color: $primary-color;
    outline: none;
  }

  &:hover {
    /* -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;*/
  }

  &>div {
    margin-right: 0 !important;
    // padding: 0 !important;
  }

  &__blue {
    box-shadow: none !important;
    @include solidBtn($primary-color, $whiteColor, $primary-color);
  }

  &-hollow {
    &__blue {
      border: 1px solid $borderColor !important;
      box-shadow: none !important;
      background-color: #fff !important;
      @include solidBtn($btn-secondary, $black, $lightGrey);
      margin-bottom: 30px;
      border-radius: 3px;
      color: $textColorBlkshade;
      width: 280px;
      position: relative;

      img,
      svg {
        margin-right: 15px;
      }

      >div {
        padding: 10px 0px 10px 20px !important;
      }

      span {
        color: $btn-secondary !important;
        font-weight: 400 !important;
      }
    }

    span {
      text-align: center;
      width: 100%;
      font-weight: 400;
    }
  }
}

.buttonActions {
  &.updateBtn {
    button {
      width: 125px;
    }
  }

  .md-w-90 {
    width: 190px !important;
  }

  .btn-outline-primary,
  .solidBtn {
    width: 155px;
  }

  .btn-outline-primary:hover {
    background-color: transparent;
    color: $black;
    border-color: $primary-color;
  }
}


.btn-Radius {
  border-radius: 4px;
  padding: 0.45rem 0.9rem;
  line-height: 1;
  border-width: 1px;
  color: $btnlightColor;
  letter-spacing: 1px;
  font-size: 0.62rem;
}

.userInfo {
  .btn-primary {
    &:hover {
      color: $midgrey !important;
      background-color: $borderColor;
      opacity: 0.8;
    }

    &:active {
      /* color: $whiteColor;*/
    }

    &:focus {
      box-shadow: none;
      color: $midgrey;
      border: 1px solid $borderColor;
    }
  }

  .btn-outline-light {
    background: $whiteColor;
    border-color: $borderColor;

    :hover {
      color: #2D2D2D;
      background-color: $whiteColor !important;
      border-color: $borderColor;
    }
  }
}

.googleLogout {
  width: 100%;
  border: 0 !important;
  box-shadow: none !important;
  display: block;

  div {
    display: none;
  }

  span {
    width: 100%;
    padding: 15px 14px 15px 18px !important;
    border: 0;
    box-shadow: none;
    display: block;
    color: #2D2D2D;
    text-align: left;
    font-weight: 400 !important;
  }
}


.dropdown-toggle {
  background: $transparent;
  color: $textColorBlkshade;
  border: $transparent;

  &:hover {
    background: $transparent;
    color: $primaryTextColor;
  }

  &:focus {
    background: $transparent;
    color: $primaryTextColor;
  }

  &:active {
    background: $transparent;
    color: $primaryTextColor;
  }
}

.dropdown-toggle:hover {
  background: $transparent;
  color: $primaryTextColor;
}

.dropdown-toggle:focus,
.dropdown-toggle:active,
.show>.btn-secondary.dropdown-toggle:focus {
  border: $transparent !important;
  box-shadow: $transparent !important;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
  background: $transparent;
  color: $primaryTextColor
}

:focus .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.455em;
  vertical-align: 0.2em;
  content: "";
  border-top: 0;
  border-right: 2px solid $textColorBlkshade;
  border-bottom: 2px solid $textColorBlkshade;
  border-left: 0;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.dropdown-item:focus,
.dropdown-item:focus,
.dropdown-item:active,
.show>.dropdown-item:active {
  background: rgba(98, 59, 165, 0.3);
  color: #1e1e1e;
}

.request-header button, .ico-btn>.btn-primary{ display: flex; font-size: 14px; align-items: center;}
.ico-btn svg{ margin-right: 10px; height: 16px !important; width: 16px !important;}

.star-rating {
  direction: rtl;
  cursor: default;

  input[type="radio"] {
    display: none;
  }

  label {
    color: #d8d8d8;
    font-size: 1.5rem !important;
    padding: 0;
    margin-bottom: 0;
    margin-right: 7px;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  label:hover,
  label:hover ~ label,
  input[type="radio"]:checked ~ label {
    color: #f2b600;
  }
}
.input-icon:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem $primary-color;
}

.select-box {
  position: relative;

  select {
    appearance: none;
  }

  &:after {
    content: "\e90a";
    speak: none;
    font-family: 'icomoon' !important;
    font-size: 0.63rem;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    right: 11px;
    top: 0;
    bottom: 0;
    height: 10px;
    margin: auto;
    opacity: 0.2;
  }
}

/* WebKit browsers */
::-webkit-input-placeholder {
  color: $lightGrey;
}

/* Mozilla Firefox 4 to 18 */
:-moz-placeholder {
  color: $lightGrey;
  opacity: 1;
}

/* Mozilla Firefox 19+ */
::-moz-placeholder {
  color: $lightGrey;
  opacity: 1;
}

/* Internet Explorer 10+ */
:-ms-input-placeholder {
  color: $lightGrey;
}

.css-1pahdxg-control:hover {
  border-color: $lightGrey;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #E9ECEF;
  opacity: 0.6;
}

.singleTag {
  .css-2b097c-container {
    height: calc(1.5em + .75rem + 10px);
    width: 110px;
  }

  &.auditSelect {
    .css-2b097c-container {
      width: 160px;
    }
  }
}

.css-1wrkhso-control {
  min-height: 47px !important;
  box-shadow: none !important;
  text-align: center;
  text-transform: uppercase;

  &:hover {
    min-height: 43px !important;
  }
}

.css-2b097c-container {
  span.css-1okebmr-indicatorSeparator {
    display: none;
  }

  .css-ig5d4d-menu {
    width: 100% !important;
    max-width: 100% !important;
  }

  .css-1bi0jn-control {
    height: 46px !important;
    border: 1.5px solid $borderColor;

    &:hover {
      border: 1.5px solid $borderColor;
    }

    &:focus {
      height: 46px !important;
      min-height: 46px !important;
      border: 1.5px solid $primary-color;
    }
  }

  .css-79tn2m-control {
    height: 46px !important;
    min-height: 46px !important;
    border: 1.5px solid $primary-color;

    &:hover {
      border: 1.5px solid $primary-color;
    }
  }

  .css-1pahdxg-control {
    box-shadow: 0 0 0 1px $primary-color;
    border-color: transparent;

    &:hover {
      border-color: $primary-color;
    }
  }

  .css-2b097c-container .css-yk16xz-control {
    height: calc(1.1em + .75rem + 10px) !important;
  }
}

.form-control {
  height: calc(1.5em + .75rem + 10px);
  border: 1.5px solid $borderColor;
  color: $midgrey !important;
  box-shadow: none;

  &:focus {
    box-shadow: none;
    border: 1.5px solid $primary-color !important;
  }
}

body {
  .input-group {
    .form-control {
      border-top: 0;
      box-shadow: none;
      border-right: 0;
      border-left: 0;
      border-bottom: 1px solid $borderColor;
      border-radius: 0;
      line-height: 2;
      padding: 10px 10px 10px 31px;
      height: 63px;
      color: $midgrey !important;
      position: relative;
      font-family: $font-family;
      font-size: 1rem;

      &:focus {
        box-shadow: none;
        border: 1px solid $primary-color;
      }

      &:after {
        width: 100%;
        -webkit-transition: all ease-in-out 0.15s;
        -o-transition: all ease-in-out 0.15s;
        transition: all ease-in-out 0.15s;
      }
    }

    .input-group-prepend {
      position: absolute;
      left: 0;
      z-index: 9;
    }

    .input-group-text {
      color: $lightGrey;
      background-color: transparent;
      border: 0;
      border-radius: 0;
      padding: 2px 10px 0px 0;
    }

    .input-group-postpend {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 4;
    }
  }
}

.loginPage {
  .loginSection {
    .solidBtn__blue {
      margin-top: 40px;
      max-width: none;
      width: auto;
    }
  }

  .form-control {
    box-shadow: none;

    &:focus {
      border: 0;
    }
  }

  span {
    color: $lightGrey;
    display: block;
    text-align: center;

    a {
      opacity: 1 !important;
      color: $primary-color;
    }
  }
}

.changepass {
  .input-group {
    position: relative;

    svg {
      position: absolute;
      right: 13px;
      top: 0;
      bottom: 0;
      margin: auto;
      background: #fff;
      z-index: 9;
    }

    .input-group-postpend {
      .input-group-text {
        padding: 2px 10px 10px 0 !important;
      }
    }

    .input-group-text {
      border-bottom: 0 solid $labelColor !important;
    }

    input {
      height: 42px;
      padding-left: 15px !important;
      border: 1.5px solid $borderColor !important;
      border-radius: 5px !important;
      overflow: hidden;

      &:focus {
        border: 1.5px solid $primary-color !important;
      }
    }
  }
}

#app {
  .contactUs {
    .input-group {
      .form-control {
        padding-left: 0;

        &:focus {
          border-bottom: 1px solid $primary-color !important;
        }
      }
    }
  }
}

.inputHelper {
  position: relative;

  .inputIcon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;
  }

  .form-control {
    padding-right: 40px;
  }
}
/*fonts.scss*/
@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon.eot?vftizq');
  src:  url('../../fonts/icomoon.eot?vftizq#iefix') format('embedded-opentype'),
    url('../../fonts/icomoon.woff2?vftizq') format('woff2'),
    url('../../fonts/icomoon.ttf?vftizq') format('truetype'),
    url('../../fonts/icomoon.woff?vftizq') format('woff'),
    url('../../fonts/icomoon.svg?vftizq#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url("../../fonts/5622d11c-c6b8-476c-9d17-995348bd8400.woff2") format("woff2"),
       url("../../fonts/d8a5d085-4356-4bc9-b496-51439ecdcd04.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url("../../fonts/b38b8bd9-d96f-4bf9-add1-adbd2b08b802.woff2") format("woff2"),
       url("../../fonts/52ed7b2d-8a31-4b18-a1d5-8685608b0889.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../fonts/ProximaNova-Light.woff2') format('woff2'),
      url('../../fonts/ProximaNova-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-switch-user:before {
  content: "\e92c";
}
.icon-contact .path1:before {
  content: "\e92a";
  color: rgb(0, 0, 0);
  opacity: 0.0100;
}
.icon-contact .path2:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(20, 20, 20);
}
.icon-Leading-Icon:before {
  content: "\e929";
}
.icon-favourite:before {
  content: "\e91c";
}
.icon-info:before {
  content: "\e91d";
}
.icon-downarrow2:before {
  content: "\e91e";
}
.icon-Union:before {
  content: "\e91f";
}
.icon-previous-arrow:before {
  content: "\e920";
}
.icon-next-arrow1:before {
  content: "\e921";
}
.icon-filter2:before {
  content: "\e922";
}
.icon-deselect:before {
  content: "\e923";
}
.icon-add-request-info:before {
  content: "\e924";
}
.icon-more:before {
  content: "\e925";
}
.icon-down-arrow1:before {
  content: "\e926";
}
.icon-Avatar:before {
  content: "\e927";
}
.icon-Icon:before {
  content: "\e928";
}
.icon-filter:before {
  content: "\e91b";
}
.icon-calendar:before {
  content: "\e918";
}
.icon-download:before {
  content: "\e919";
}
.icon-Star:before {
  content: "\e91a";
}
.icon-upload:before {
  content: "\e917";
}
.icon-Tickmark:before {
  content: "\e916";
}
.icon-back-arrow1:before {
  content: "\e915";
}
.icon-Google:before {
  content: "\e910";
}
.icon-Office:before {
  content: "\e911";
}
.icon-close:before {
  content: "\e912";
}
.icon-Email:before {
  content: "\e913";
}
.icon-open-eye:before {
  content: "\e914";
}
.icon-X:before {
  content: "\e909";
}
.icon-home:before {
  content: "\e90c";
}
.icon-close-eye:before {
  content: "\e900";
}
.icon-edit:before {
  content: "\e901";
}
.icon-user-3:before {
  content: "\e902";
}
.icon-trash:before {
  content: "\e903";
}
.icon-up-down-arrow:before {
  content: "\e904";
}
.icon-reset-password:before {
  content: "\e905";
}
.icon-search:before {
  content: "\e906";
}
.icon-back-arrow:before {
  content: "\e907";
}
.icon-backward-arrow:before {
  content: "\e908";
}
.icon-down-arrow:before {
  content: "\e90a";
}
.icon-forward-arrow:before {
  content: "\e90b";
}
.icon-lock:before {
  content: "\e90d";
}
.icon-login-as:before {
  content: "\e90e";
}
.icon-next-arrow:before {
  content: "\e90f";
}

@import '../../abstracts/mixins';

.userdetails {
    .form-group {
        display: flex;
        align-items: baseline;

        label {
            width: 160px;
            text-transform: uppercase;
            letter-spacing: initial !important;
            line-height: 1rem;
            font-size: 0.9rem !important;
        }

        span {
            display: inline-block;
            font-size: 15px;
            color: $midgrey;
            white-space: break-spaces;
            width: 100%;
            line-height: 1.3;
            color: $textColorInput;
        }
    }

    .tag-span {
        background: #E9ECEF;
        width: auto !important;
        display: inline-block !important;
        padding: 4px 10px;
        border-radius: 2px;
        margin: 0px 8px 8px 0px;
    }
}

.userInfoMod {
    .userEmailInfo {
        padding: 0;

        .avimg-90 {
            div {
                height: 90px;
                width: 90px;
                border: 1.5px solid $primary-color;
                border-radius: 50%;
                object-fit: cover;
                overflow: hidden;
                margin-right: 13px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .userInfo {
            h5 {
                font-size: 1rem;
                color: $lightGrey;
            }

            h4 {
                font-size: 1.125rem;
                color: $textColorBlkshade;
            }
        }

        .avatar {
            div {
                overflow: hidden;

                img {
                    height: 100%;
                    width: 100%;
                    border: 4px solid #fff;
                    object-fit: cover;
                    border-radius: 50%;
                }
            }
        }
    }
}

.react-tabs__tab-list {
    border-width: 2px;
    display: flex;
    margin-bottom: 32px;
    border-bottom: 1px solid $borderColor;
    padding: 0;

    .buttonActions {
        flex-grow: 1;
        justify-content: flex-end;
        margin-bottom: 0.7rem;
    }

    .react-tabs__tab {
        border: 0px;
        padding: 1.5rem 0;
        margin: 0 1rem;
        color: #474747;
        cursor: pointer;

        &.react-tabs__tab--selected {
            position: relative;
            color: $primary-color;
            outline: none;
            cursor: auto;

            &:after {
                border-bottom: 2px solid $primary-color;
                background: $primary-color;
                outline: none;
                content: "";
                position: absolute;
                bottom: -2px;
                height: 3px;
                width: 100%;
                left: 0;
            }
        }
    }
}

.react-tabs__tab-list .react-tabs__tab:first-child {
    margin-left: 0;
}

.react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected {
    position: relative;
    color: $primary-color;
    outline: none;
    cursor: text;
    text-align: center;
}

.react-tabs__tab-list .react-tabs__tab {
    text-align: center;
}
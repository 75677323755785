.pagination {
  float: right;
  margin-bottom: 0;

  li {
    border: 1px solid $borderColor;
    border-radius: 4px;
    margin: 0px 4px;
    font-size: 14px;
    line-height: 12px;
    cursor: pointer;

    &.active {
      background: hsla(120, 100%, 50%, 0.3);
      background: #f2eaff;
      border: 1px solid $primary-color;
    }

    a {
      color: $midgrey;
      vertical-align: text-top;
      padding: 6px 9.3px;
      vertical-align: text-top;
      border-radius: 4px;
      font-size: 14px;
      line-height: 14px;
      display: inline-block;
    }

    &.ForwardText,
    &.next {
      img {
        transform: rotate(180deg);
      }
    }

    &.previous,
    &.next {
      text-transform: capitalize;
    }
  }
}
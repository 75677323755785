@import "../abstracts/variables";

@mixin solidBtn($primary-color, $whiteColor, $transparent) {
  border: 1px solid rgba(255, 255, 255, 0);
  /*color: $whiteColor;*/
  background-color: $primary-color;
  border-color: $transparent;

  &__blue {
    background-color: $primary-color;
    color: $primary-color;
    border-color: $primary-color;

  }

  &-hollow {
    color: $primary-color;
    border: 1px solid $primary-color;
    &__blue {
     /* background-color: transparent;*/
      border-color: $lightGrey;

      &:hover,
      &:active {
        opacity: 1;
        border-color: $transparent;
      }
    }
  }

  &:hover,
  &:active {
    opacity: 1;
    border-color: $transparent;
  }
}

// End Mixin

@mixin flexbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin align-items-center {
  -ms-flex-align-items: center;
  -webkit-align-items: center;
  align-items: center;
}

@mixin box-shadow($left, $top, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow: inset $left $top $blur $color;
    -moz-box-shadow: inset $left $top $blur $color;
    box-shadow: inset $left $top $blur $color $color;
  }

  @else {
    -webkit-box-shadow: $left $top $blur $color;
    -moz-box-shadow: $left $top $blur $color;
    box-shadow: $left $top $blur $color;
  }
}

/*reset.scss*/
html,
body,
#app {
  /* min-height: 94vh;*/
}

body {
  background: transparent;
}

#app {
  position: relative;
  border-radius: 32px;
  margin: 0 30px;
}

.radiusContainer {
  border-radius: 32px;
  background: #fff;
  margin: 32px 0;
  padding-bottom: 32px;
}

@media(max-width: 480px) {

  .radiusContainer {
    height: 100% !important;
  }

  .content-div {
    height: 100% !important;
  }

  .feedWrap {
    position: relative !important;
    left: 18px !important;
  }

}

html,
body,
p {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  font-family: $font-family;
  color: $midgrey;
  font-weight: normal;
}

body {
  line-height: 1;

  a,
  a:hover,
  a:focus {
    text-decoration: none !important;
  }

  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  img {
    max-width: 100%;
  }
}

::placeholder {
  color: red;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: red;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: red;
}

.radiusContainer {
  padding: 25px 0;
  height: 93vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .inner-row {
    border-radius: 32px;
    background: #fff;
    flex: 0 0 100%;
    overflow: hidden;
    height: calc(100vh - 50px);
    padding-top: 12px;
  }
}

header {
  border-bottom: solid 1px $borderColor;
}

$primary-color: #623BA5;
$lightGrey: #3D3D3D;
$midgrey: #2d2d2d;
$orangeColor: #ff5722;
$whiteColor: #ffffff;
$purpleColor: #AD337B;
$blueColor: #0072ff;
$btn-secondary: #ffffff;
$font-family: 'Proxima Nova';
$transparent: transparent;
$black: #000;
$labelColor: #666666;
$labelColor2: #8F8F8F;
$borderColor: #d6d6d6;
$borderColorTable:#E0E0E0;
$btnlightColor: #fff;
$textColorBlkshade: #1e1e1e;
$textColorgryShade: #666666;
$textColorInput: #1E1E1E;
$BgColorGrey: #E4E4E4;
$textColordarkGrey: #3D3D3D;
$primaryTextColor: #623BA5;
$tableTrHover: #f6f4f9;
$borderRadius: 8px;
$largeFont: 18px;
$bodyFont-size:16px;
$bodySmallFont-size: 14px;
$bodyFontLight: 400;
$backgroundColor: #FDFAF3;


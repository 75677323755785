@import '../../abstracts/mixins';

.loginPage {
  display: flex;
  align-items: center;
  background: url('../../../images/Background_form.png') center center;
  background-repeat: no-repeat;
  background-color: #fff;
  background-size: cover;
  min-height: 100vh;
  height: 100%;
  align-items: stretch;

  .form-group {
    margin-bottom: 2.5rem;
  }

  .form-control {
    box-shadow: none;
    height: auto !important;
    padding: 0 0 5px 30px !important;
    background-color: $transparent;
    border-bottom-color: $lightGrey !important;

    &:focus {
      box-shadow: none;
      border: 0 !important;
      border-bottom: 1px solid $primary-color !important;
    }
  }

  .loginSection {
    max-width: 100%;
    width: 630px;
    margin: auto;

    img {
      max-width: 300px;
    }
  }

  >div {
    >div {
      height: 100%;
    }
  }

  .divider {
    display: block;
    text-align: center;
    text-transform: capitalize;
    margin: 24px 0px;
    color: $lightGrey;
  }

  .h1 {
    margin-bottom: 40px;
    text-align: center;
    font-weight: 400;
  }

  .fullWidth-100 {
    max-width: 100%;
  }

  .extraPadd {
    padding: 50px 109px;
    @include flexbox;
    @include align-items-center;
  }

  .btn-link {
    padding: 0px;
    margin: 0;
    text-align: right;
    width: 100%;
    font-size: 13px;
    color: lighten($primary-color, 10%);
    font-weight: 400;

    &:hover {
      text-decoration: none;
    }
  }

  .remember_me {
    font-size: 14px;

    span {
      vertical-align: middle;
      display: inline-block;
      padding-top: 0px;
      color: $lightGrey;
    }

    input {
      margin-right: 10px;
      vertical-align: middle;
    }

    &.check-container {
      .checkmark {
        top: 2px;
        width: 15px !important;
        height: 15px !important;

        &:after {
          left: 3px;
          top: 1px;
        }
      }

      label {
        padding-left: 1rem;
      }

    }
  }

  .rightSidebarBG {
    background-image: url("../../../images/bg-image.png");
    background-repeat: no-repeat;
    background-position: 50% 28% !important;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px 90px;

    .h1 {
      margin: 60px 0px 0 0;
    }

    h1,
    h2,
    p,
    li {
      color: $whiteColor;
    }

    hgroup {
      padding: 0 70px;

      h2 {
        font-weight: 500;
        letter-spacing: 0;
        line-height: 60px;
      }

      p {
        line-height: 21px;
        margin-top: 2rem;
      }
    }

    .footerCopyright {
      text-align: center;

      p {
        margin-top: 0.2rem;
        line-height: 20px;
      }

      ul {
        margin: 0;

        li {
          display: inline-block;
          padding: 0px 15px;
          position: relative;

          &:nth-last-child(1):after {
            display: none;
          }

          &:after {
            content: "";
            right: 0;
            width: 1px;
            height: 17px;
            background: $whiteColor;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
          }

          a {
            color: $whiteColor;
            font-size: 0.9375rem;
            opacity: 1;
            line-height: 20px;
          }
        }
      }
    }
  }
}
.table-wrapper {
  mask-size: 100% 20000px;
  mask-position: left bottom;
  -webkit-mask-size: 100% 20000px;
  -webkit-mask-position: left bottom;
  transition: mask-position 0.3s, -webkit-mask-position 0.3s;
  overflow-x: hidden;

  &:hover {
    -webkit-mask-position: left top;
    border-bottom-right-radius: 32px;
  }
}

@keyframes background {
  from {
    background: pink;
  }

  to {
    background: #c0d6ff;
  }
}

.table {
  color: $textColordarkGrey;
  font-size: 0.875em;

  thead {
    th {
      border-bottom: solid 1px $borderColor;
      border-top: none;
      color: $midgrey;
      font-weight: 600;
      white-space: nowrap;

      span {
        text-overflow: ellipsis;
      }
    }
  }

  tr {
    &:hover {
      background-color: $tableTrHover;
      cursor: pointer;
    }
  }

  td {
    padding: 0.75rem;

    svg {
      margin-right: 5px;
    }
  }

  td,
  th {
    border-bottom: solid 1px $borderColor;
    border-top: none;
  }

  td:first-child,
  th:first-child {
    max-width: 6px;
    padding: 0;
    font-size: 0;
    width: 6px;
  }

  tr:hover > td:first-child,
  tr:hover > th:first-child {
    background-color: $primary-color;
  }

  td {
    h5 {
      font: {
        size: 1rem;
        weight: 600;
      }

      line-height: 20px;
      margin-bottom: 2px;
    }
  }
}

@media (max-width: 600px) {
  .siteFilter.d-flex.align-items-top.search.pt-3.pl-3 {
    flex-direction: column;
    width: 70%;
  }

  .siteFilter {
    .search-header {
      margin-bottom: 10px;
    }

    .search-input {
      width: 100%;
    }

    .form-inline .singleTag {
      width: 75%;
    }
  }
}

@media (max-width: 520px) {
  .siteFilter .form-inline .singleTag {
    width: 58%;
  }
}

.contact-list-table {
  .company_name {
    display: none;
  }
}

@media (max-width: 480px) {
  .table {
    margin: 10px;

    th {
      padding: 0.45rem;
    }

    td {
      padding: 0.45rem;

      &::before {
        font-weight: bold;
      }
    }

    .linkedin-ico {
      margin: 0 10px;
    }

    thead th {
      display: none;
    }

    thead th:nth-child(2) {
      display: block;

      input[type='checkbox']::after {
        content: 'Select All';
        margin-left: 20px;
        white-space: nowrap;
      }
    }

    tbody {
      display: grid;
      border: 0;
    }

    tr {
      display: block;
      border-bottom: solid 1px $borderColor;
      width: 100%;
    }

    td {
      display: block;
      border: 0;
    }
  }

  .contact-list-table {
    margin: 0;

    thead th {
      display: table-cell;
    }

    thead th:nth-child(2) {
      input[type='checkbox']::after {
        display: none;
      }
    }

    tbody {
      display: table-row-group;
    }

    tr {
      display: table-row;
    }

    td {
      display: table-cell;
    }

    td {
      display: none;
    }

    td:nth-child(1),
    td:nth-child(2),
    td:nth-child(3) {
      display: table-cell;
    }

    .company_name {
      display: table-cell;
    }

    td:nth-child(4)::before {
      content: 'Company';
      margin-right: 10px;
      color: #000;
      width: 100%;
      display: inline-flex;
    }

    td:nth-child(5)::before {
      content: 'Preffered Roll';
      margin-right: 10px;
      color: #000;
      width: 100%;
      display: inline-flex;
    }

    td:nth-child(6)::before {
      content: 'Functions';
      margin-right: 10px;
      color: #000;
      width: 100%;
      display: inline-flex;
    }

    td:nth-child(7)::before {
      content: 'Previous Companies';
      margin-right: 10px;
      color: #000;
      width: 100%;
      display: inline-flex;
    }

    td:nth-child(8)::before {
      content: 'Status';
      margin-right: 10px;
      color: #000;
      width: 100%;
      display: inline-flex;
    }
  }

  .user-list-table {
    th {
      display: none !important;
    }

    td:nth-child(2)::before {
      content: 'Name';
      margin-right: 10px;
      color: #000;
      width: 100%;
      display: inline-flex;
    }

    td:nth-child(3)::before {
      content: 'Company';
      margin-right: 10px;
      color: #000;
      width: 100%;
      display: inline-flex;
    }

    td:nth-child(4)::before {
      content: 'Title';
      margin-right: 10px;
      color: #000;
      width: 100%;
      display: inline-flex;
    }

    td:nth-child(5)::before {
      content: 'Email';
      margin-right: 10px;
      color: #000;
      width: 100%;
      display: inline-flex;
    }

    td:nth-child(6)::before {
      content: 'Role';
      margin-right: 10px;
      color: #000;
      width: 100%;
      display: inline-flex;
    }

    td:nth-child(7)::before {
      content: 'Status';
      margin-right: 10px;
      color: #000;
      width: 100%;
      display: inline-flex;
    }

    td:nth-child(8)::before {
      content: 'Last Login';
      margin-right: 10px;
      color: #000;
      width: 100%;
      display: inline-flex;
    }
  }

  .audit-trail-list {
    th {
      display: none !important;
    }

    td:nth-child(2)::before {
      content: 'Sender';
      margin-right: 10px;
      color: #000;
      width: 100%;
      display: inline-flex;
    }

    td:nth-child(3)::before {
      content: 'Subject';
      margin-right: 10px;
      color: #000;
      width: 100%;
      display: inline-flex;
    }

    td:nth-child(4)::before {
      content: 'Message';
      margin-right: 10px;
      color: #000;
      width: 100%;
      display: inline-flex;
    }

    td:nth-child(5)::before {
      content: 'About Your Company';
      margin-right: 10px;
      color: #000;
      width: 100%;
      display: inline-flex;
    }

    td:nth-child(6)::before {
      content: 'About Your Oppurtunity';
      margin-right: 10px;
      color: #000;
      width: 100%;
      display: inline-flex;
    }

    td:nth-child(7)::before {
      content: 'Other';
      margin-right: 10px;
      color: #000;
      width: 100%;
      display: inline-flex;
    }

    td:nth-child(8)::before {
      content: 'Type';
      margin-right: 10px;
      color: #000;
      width: 100%;
      display: inline-flex;
    }

    td:nth-child(8)::before {
      content: 'Date Submitted';
      margin-right: 10px;
      color: #000;
      width: 100%;
      display: inline-flex;
    }
  }

  .salesforce-list {
    th {
      display: none !important;
    }

    td:nth-child(2)::before {
      content: 'Run Date';
      margin-right: 10px;
      color: #000;
      width: 100%;
      display: inline-flex;
    }

    td:nth-child(3)::before {
      content: 'Pass | Fail';
      margin-right: 10px;
      color: #000;
      width: 100%;
      display: inline-flex;
    }

    td:nth-child(4)::before {
      content: 'New Data | Count';
      margin-right: 10px;
      color: #000;
      width: 100%;
      display: inline-flex;
    }
  }

  .contact-name {
    justify-content: space-between !important;
  }

  /* .table td {
    display: table-row; border: 0; padding: 0 10px; line-height: 30px;
  }
	.table tr{ border-bottom: solid 1px $borderColor !important;}*/

  .admin-filter button.btn.btn-primary {
    min-width: 105px;
  }

  .filter-btn {
    font-size: 0 !important;
    min-width: 32px;
    height: 32px;
    margin-left: 5px;
  }

  .siteFilter {
    .filter-btn img {
      left: 12px !important;
      top: 11px !important;
    }

    .form-inline .singleTag {
      width: 70%;
    }
  }

  .siteFilter.col-gap {
    grid-column-gap: 0px;
    column-gap: 0px;
    flex-wrap: wrap;
  }

  .filter-btn svg {
    margin-right: 0 !important;
  }

  .aside-filter {
    width: 84%;
    min-width: 280px !important;
  }
}

.table-active,
.table-active > td,
.table-active > th {
  background-color: rgba(98, 59, 165, 0.07);
}

@media (max-width: 420px) {
  .siteFilter.d-flex.align-items-top.search.pt-3.pl-3 {
    flex-direction: column;
    width: 70%;
  }

  .siteFilter {
    .search-header {
      margin-bottom: 10px;
    }

    .search-input {
      width: 98%;
    }

    .form-inline {
      width: 100%;
    }

    .form-inline .singleTag {
      width: 100%;
    }
  }

  .admin-filter button.btn.btn-primary {
    min-width: 105px;
  }
}

.tooltip-ui {
  text-align: left;
  color: $midgrey;
  padding: 10px 10px 0 10px !important;
  margin: 0 !important;
  left: 0 !important;
}

.tooltip-inner:last-child {
  padding-bottom: 10px;
}

.tooltip-arrow {
  // border-color: red;
}

.tooltip-inner {
  background-color: #fff;
  border: solid 1px $borderColor
}

.tooltip.bs-tooltip-right .arrow:before {
  border-right-color: transparent !important;
}

.tooltip.bs-tooltip-left .arrow:before {
  border-right-color: transparent !important;
}

.tooltip.bs-tooltip-bottom .arrow:before {
  border-right-color: transparent !important;
}

.tooltip.bs-tooltip-top .arrow:before {
  border-right-color: transparent !important;
}

/*.tooltip.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.bs-tooltip-right .arrow::before {
  margin-top: -3px;
  content: "";
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}
.tooltip.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.bs-tooltip-bottom .arrow::before {
  margin-left: -3px;
  content: "";
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}
.tooltip.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.bs-tooltip-left .arrow::before {
  right: 0;
  margin-top: -3px;
  content: "";
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
}
.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.bs-tooltip-top .arrow::before {
  margin-left: -3px;
  content: "";
  border-width: 5px 5px 0;
  border-top-color: #000;
}
*/

.tooltip.bs-tooltip-right .arrow:before {
  border-right-color: $borderColor !important;
}

.tooltip.bs-tooltip-left .arrow:before {
  border-left-color: $borderColor !important;
}

.tooltip.bs-tooltip-bottom .arrow:before {
  border-bottom-color: $borderColor !important;
}

.tooltip.bs-tooltip-top .arrow:before {
  border-top-color: $borderColor !important;
}
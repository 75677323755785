$introducedBG: #F2EAFF;
$reqestedBG: #EAFFEA;
$hiredBG: #EAFBFF;
$introducedColor:#009ABE;
$requestedColor: #2A8D34;
$hiredColor: #009ABE;

.badge{ 

padding:8px; font-size: $bodySmallFont-size; font-weight:$bodyFontLight; border-radius: $borderRadius;

}

.badge-introduced{ background-color: $introducedBG; color: $primary-color;}
.badge-requested{ background-color: $reqestedBG; color: $requestedColor;}
.badge-hired{ background-color: $hiredBG; color: $hiredColor;}

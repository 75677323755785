.headercustom {
  padding: 0.4rem 1rem;
  border-radius: 0;
  border: 0;

  .navbar-brand {
    height: auto;

    img {
      height: 70px;
    }
  }

  .userAvatar {
    width: 150px;
    display: flex;
    justify-content: flex-end;
  }
}

#HeaderWrap {
 // padding-bottom: 16px;
  border-bottom: solid 1px $borderColorTable;
}

.header-wrapper {
  padding: 0 24px;
  margin-bottom: 5px;
}

.dropdown-toggle {

  div {
    height: 32px;
    width: 32px;
    line-height: 32px;
    font-weight: bold;
    border: solid 1px $borderColor;
    color: $primary-color;
    border-radius: 50%;
    float: left;
  }

  &::after {
    right: -3px;
    top: 3px !important;
  }
}

.dropdown-toggle::after {
  margin-top: 10px;
  margin-left: 5px;
}



.dropdown-initial .initials {
  border: solid 1px $borderColor;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  text-align: center;
  line-height: 32px;
  font-weight: bold;
  color: $primary-color;
  margin-right: 10px;
  margin-top: 5px;
}

.content-div {
  /*border-top: solid 1px $borderColorTable;*/
}

.m-70 {
  margin-top: 70px;
}

.header {
  /*  padding: 50px 18px;*/
  padding: 0;
  margin: 0px;
  position: sticky;

  width: 100%;

  .hdr-title {
    position: relative;
    z-index: 999999;
  }

}

.profile-dropdown {
  width: max-content;
  background: #fff;
  position: absolute;
  left: auto !important;
  transform: none !important;
  top: 40px !important;
  right: 0 !important;

  .profile-link:focus,
  .profile-link:hover {
    background: none !important;
    text-decoration: underline !important;
    color: $primaryTextColor;
  }

  .dropdown-item:focus,
  .dropdown-item:focus,
  .dropdown-item:active,
  .show>.dropdown-item:active,
  .dropdown-item:focus,
  .dropdown-item:hover {
    background: none;
  }

  .username {
    font-size: 18px;
    color: $lightGrey;
    font-weight: bold;
  }

  .useremail {
    color: $midgrey;
    font-size: 16px;
  }

  .username:hover,
  .useremail:hover {}

  .profile-img {
    margin-right: 10px;
    margin-top: 5px;
  }

  .profile-link {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .dropdown-driver {
    margin: .5rem 20px;
  }
}

.profile-btn {
  height: 32px;
  width: 32px;
  margin-right: 5px;
  border: solid 1px $borderColor;
}

.feedback-header div {
  line-height: 21px;
  vertical-align: middle;
}

.feedback-header svg {
  margin-right: 5px;
  top: -1px;
}

.hdr-logo {
  margin: 6px;
}

.userAvatar {
  .avatar {
    padding: 0 8px;

    div {
      height: 40px;
      object-fit: cover;
      width: 40px;
      border-radius: 1000px;
      vertical-align: bottom;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      border: 1px solid $purpleColor;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .dropdown-menu {
    padding: 0;

    button {
      padding: 1rem;
      border-bottom: 1px solid #efefef;
      font-size: 14px;

      &:nth-last-child(1) {
        border-bottom: 0;
      }

      &.active,
      &:active,
      &:focus,
      &:hover {
        color: #000;
        text-decoration: none;
        background-color: #f8f9fa;
        outline: none;
      }
    }
  }

  .dropdown a {
    display: flex;
    align-items: center;
  }

  .dropdown-menu.dropdown-menu-right {
    border: 0;
    box-shadow: (0px 0px 6px 0 #33333338);
  }

  .dropdown-menu.dropdown-menu-right:after {
    content: "";
    right: 30px;
    top: -7px;
    border-top: none;
    z-index: -999;
    position: absolute;
    width: 0;
    height: 0;
    bottom: 0;
    box-sizing: border-box;
    border: 0.5em solid white;
    border-color: transparent transparent #ffffff #ffffff;
    transform: rotate(136deg);
    box-shadow: -2px 2px 3px 0 #33333338;
  }
}

.header-div {
  justify-content: space-between;
}

//.red{top: 10px !important;}
nav {
  .navbar-nav {
    &.menu {
      li {
        a {
          color: $midgrey !important;
          border-radius: 8px;
          padding: 0.3rem 0.7rem 3rem 0.7rem !important;
          margin: 0 5px;
          font-size: 14px;
          padding: 0.3rem 2rem !important;
          height: 32px;
          line-height: 21px;
          border: 1px solid $borderColor;
          cursor: pointer;
          background: $whiteColor;
          transition: all ease-in-out 350ms;
          white-space: nowrap;

          &:hover,
          &.active {
            background: $whiteColor;
            color: $primary-color !important;
            border-color: $primary-color;
            cursor: auto;
          }
        }
      }
    }

    .nav-link {
      color: $midgrey;
      cursor: pointer !important;
    }
  }

  .dropdown-menu {
    min-width: 13rem;
  }

  .show {
    top: 55px;
    left: 10px;
  }

  .nav-item {
    margin-bottom: 5px;
  }
}

.userEmailInfo {
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;

  .avatar {
    display: inline-block;

    div {
      border: 0;
      width: 30px;
      height: 30px;
      object-fit: cover;
      box-shadow: 0 0 0 1px #dee2e6;
      text-transform: uppercase;
    }
  }

  .userInfo {
    h4 {
      font-size: 0.9rem;
      color: #455061;
      font-weight: 500;
    }

    h5 {
      font-size: 12px;
      margin-top: 5px;
      opacity: 0.7;
    }

    .changeprofile {
      display: inline-block;
      cursor: pointer;
      padding: 10px;
      border: 0.1rem solid $lightGrey;
      color: $lightGrey;
      margin-bottom: 10px;
      font-size: 0.7rem;
      border-radius: 4px;
      overflow: hidden;
      position: relative;

      input {
        position: absolute;
        left: 0;
        cursor: pointer;
        opacity: 0;
      }
    }
  }
}

.header-div>.dropdown {
  width: 60px;
  display: flex;
  flex-direction: row;
  text-align: center;
}

.header-div>.dropdown>button {
  min-height: 48px;

}

.toggle-div {
  display: none;
}

.red {
  position: absolute;
  padding: 0;
  right: 0;
  /* top: 15px;*/
  margin: auto;
  left: 0;
  width: 50%;
  top: 9px;
}


@media (max-width: 768px) {
  .app-logo {
    margin-right: 7px;
  }

  .dropdown-toggle::after {
    top: 3px !important;
  }

  .radiusContainer .inner-row {
    padding: 0;

  }

  // .header-wrapper{ padding-right: 5px;}
  .header-div {
    justify-content: flex-start;
    margin-top: 16px;
    width: 100%;
   // border: solid 1px red;

    .dropdown {
      right: 16px;
      z-index: 9999;

      button {
        margin-top: -2px;
        min-height: 40px;
      }

    }

  }

  .navbar-light .navbar-toggler {
    width: 32px;
    padding: 3px 7px 3px 3px !important;
  }

  .navbar-light .navbar-toggler-icon {
    height: 24px !important;
    width: 24px !important;
  }

  .collapse {
    z-index: 9999;
    transform: none;
    transition: none;

  }

  .browse-header {
    width: 95%;
    margin-top: -5px
  }

  .profile-btn {
    height: 24px;
    width: 24px;
    margin: 0;
  }

  .toggle-div {
    display: block;
  }

  .red {
    width: 100%;
    top: 12px !important;
  }

  .feedWrap {
    right: 115px !important;
    margin-top: 0 !important;
  }
}


@media (max-width: 480px) {
  .feedBackButton {
    padding-top: 5px;
  }

  .header-div {
    justify-content: flex-start;
    margin-top: 0;
    width: 100%;
  }

  .toggle-div {
    display: block;
  }

  .red {
    width: 100%;
    top: 18px !important;
  }

  .center-logo {
    display: none;
    width: 225px;
    height: 25px;
  }
}

//.red{ position: absolute;padding: 0; right: 0; top: 15px;}

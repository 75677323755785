/*aside.scss*/
.aside {
  border-left: $borderColor;

  .aside-sec {
    background-color: #fff;

    ::-webkit-scrollbar {
      display: none;
    }

    .aside-header {
      max-width: 500px;
      position: sticky;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 24px;
      border-bottom: 1px solid $borderColor;

      .hide-slide {
        height: 32px;
        width: 32px;
      }

      h3.aside-header-text {
        font-size: 21px;
        line-height: 36px;
        letter-spacing: 0.5px;
        margin-bottom: 0;
        font-weight: bold;
        float: left;
      }
    }
  }

  .aside-form {
    overflow-y: auto;
    height: calc(100% - 430px);
    padding-right: 15px;
    max-width: 460px;

    .container {
      margin-bottom: 16px;
      padding: 1rem 1rem 0 1rem;
    }

    h4 {
      font-size: 1.12rem;
      line-height: 21px;
    }

    label {
      font-size: $bodySmallFont-size;
      color: $labelColor2;
    }
  }
}

.close-btn {
  background: $transparent;
  border: none;
}

.aside-footer {
  border-top: solid 1px $borderColor;
  /* background: grey; */
  position: sticky;
  bottom: 0;
  z-index: 999999;
  height: 46px;
  background: #fff;
  max-width: 490px;
}

.aside-footer-container {
  margin-top: 10px;
  margin-left: 16px;
  margin-right: 16px;

  p {
    line-height: 30px;
    color: $midgrey;
    font-size: 14px;
  }
}

.rounded-3 {
  border-radius: 12px;
}

.contact-hdr {
  width: 95%;
}

/***** Global Slide *****/
/*.slide-right,
.slide-left {
  width: 0;
  top: 160px;
}*/

/***** Slide Right *****/
.slide-right {
  animation: 2s slide-right;
}

@keyframes slide-right {
  from {
    margin-left: -100%;
  }

  to {
    margin-left: 0%;
  }
}

@media(max-width: 480px) {
  .form-block {
    font-size: 0.95rem;
  }

  .my-4 {
    margin-bottom: 1rem !important;
  }

  .aside {
    height: 100%;

    .aside-sec {
      display: flex;
      flex-direction: column;
    }

    .aside-form {
      height: calc(100% - 84px);
      margin-bottom: 50px;
    }
  }

  .aside .aside-form h4 {
    font-size: 1rem;
    font-weight: 500;
  }

  .aside-header-text {
    font-size: 1.1rem;
  }

  .aside .aside-sec .aside-header {
    padding: 0.5rem 1rem;
  }

  .aside-filter {
    .card-body {
      padding: 1rem;
    }

    .aside-form .card-header {
      padding-bottom: 0;
    }
  }

  .filter-box {
    margin: 8px 0;
  }
}


/***** Slide Left *****/

.tblWraps {
  position: relative;
  padding-bottom: 16px;

  .aside {
    position: absolute;
    right: 0;
    top: 0 !important;
    z-index: 99;
    width: auto;
  }

  .collapse-table {
    padding-right: 450px;
    transition: all ease 2s;
  }


  @keyframes slide-left {
    from {
      right: -300px;
    }

    to {
      right: 0;
    }
  }

  @keyframes slide-left-close {
    from {
      right: 0;
    }

    to {
      right: -300px;
    }
  }


}

@media only screen and (min-width: 480px) {
  .slide-left-animate {
    // transition: all ease 2s; width: 25%; right: 40px;
    animation: slide-left 2s;
  }
}

@media only screen and (max-width: 480px) {
  .aside {
    width: 100% !important;
  }
}



/***** FadeIn Underline *****/
.line {
  border-bottom: 3px solid red;
  width: 200px;
  margin: auto;
}

.fade-in {
  animation: fadeIn ease 3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


/*Left panel starts here*/

.aside-clear {
  border-top: solid 1px $borderColor;
  /* background: grey; */
  position: sticky;
  bottom: 0;
  z-index: 999999;
  height: 46px;
  background: #fff;

  button {
    padding: 10px 0 0 20px;
  }
}

.aside-filter {


  .aside-sec::-webkit-scrollbar {
    display: none;
  }

  .aside-header {
    max-width: 500px;
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    border-bottom: 1px solid $borderColor;

    h4 {
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 0;
    }

  }

  .aside-form {
    padding: 16px;
    z-index: 9;
    overflow-y: auto;
    height: calc(100% - 300px);

    .card {
      border-radius: 0.50rem;
      margin-bottom: 16px;
    }

    .card-header {
      background-color: transparent;
      border: none;
    }

    .card-body p {
      line-height: 28px;
      font-size: 14px;
      padding: 0 1.25rem 0 0;
    }

    .accordion-button {
      width: 100%;
      float: left;
      text-align: left;
      background: none;
      border: none;
      font-size: 1.25rem;
      font-weight: 400;
      padding: 0;
    }

    .accordion-button::after {
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      margin-left: auto;
      float: right;
      font-size: 1.15rem;
      /* background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);*/
      transition: var(--bs-accordion-btn-icon-transition);
    }
  }
}





/* aside css starts here */
.aside-filter {
  width: 25vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 992;
  top: 0 !important;
  left: 0px !important;
  border-right: solid 1px $borderColor;
  min-width: 320px;
}

.aside-sec {
  width: 100%;
  max-width: 450px;
  height: 100vh;
  background-color: #fff;
  margin-left: auto;
}

.aside-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.aside-header-text {
  font-size: 20px;
  color: #353c42;
  margin-bottom: 0;
}

.rounded-3 {
  border-radius: 12px;
}

.cstm-close-button {
  background: none;
  border: none;
}

.addt-cnt {
  background-color: #000000;
  color: #ffffff;
  min-width: 28px;
  width: 28px;
  block-size: fit-content;
  height: 28px;
  font-size: 0.65rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.render-cont {
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  padding: 4px 8px 4px 8px !important;
  margin-right: 5px;
  font-size: 0.87rem;
}

.render-cont svg {
  background-color: #f0f0f0;
  padding: 5px;
  margin: 0 8px 0 0;
}

.browse-header .drop-butt-trans button {
  color: #1e1e1e !important;
  font-size: 16px;
}

.browse-header .dropdown {
  padding-right: 5px;
}

.browse-header .dropdown-toggle::after {
  position: absolute;
  right: 0px;
  top: 13px;
}


.text-dark {
  color: #1e1e1e !important;
}

.text-black {
  color: #000000 !important;
}

.font-2 {
  font-size: 1.5rem !important;
}

.font-1 {
  font-size: 0.875rem !important;
}

.feedback-main .dropdown-toggle::after {
  position: absolute;
  top: 17px;
}

.request-header button {
  font-weight: bold;
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.rounded-8 {
  border-radius: 8px;
}

.pr-10 {
  padding-right: 10px;
}

.pl-30 {
  padding-left: 30px;
}

.search-input {
  border: 1px solid rgba(0, 0, 0, 0.16);
}

.search-header input::placeholder {
  color: #6C6C6C;
}

.col-gap {
  column-gap: 12px;
}

.search-btn {
  width: 130px;
}

.contact-gap {
  column-gap: 5px;
}

.siteFilter {
  padding-bottom: 16px;

  .search-btn {
    font-size: 14px;
    padding: 8px;

    button {
      display: flex;
      white-space: nowrap;
    }
  }

  .search-header input {
    // padding: 7px 10px 6px 30px;
    padding: 0.375rem 0.75rem 0.375rem 30px;
    font-size: 14px;
    line-height: 1.5;
  }

  .filter-btn {
    font-size: 14px;
    color: $textColorInput;

    img {
      position: absolute;
      left: 7px;
      top: 12px;
    }

    svg {
      margin-right: 10px;
      width: 18px;
    }

    &:hover {
      background-color: #ffffff;
      color: $textColorInput;
      border: 1px solid rgba(0, 0, 0, 0.16);

    }

  }

  .filter-badge {
    background: $primary-color;
    border-radius: 20px;
    padding: 5px 5px;
    font-size: 12px;
    left: 5px;
    min-width: 22px;
    width: 22px;
  }

  .filter-box {
    border-color: $primary-color !important;
    font-size: 14px;
    background: rgba(98, 59, 165, 0.08);
    margin: 2px 0;
  }

  .search-input {
    padding: 5px 15px;
    line-height: 1.5;
  }

  .filter-type {
    color: $lightGrey;
    font-weight: bold;
  }

  .filter-value {
    color: $primary-color;
    padding-left: 5px;
    font-weight: 500;
  }

  .filter-separator {
    display: inline-block;
    padding-left: 5px;
    color: rgba(98, 59, 165, 0.24);
    font-size: 14px;
  }
}

.border-padding {
  padding: 1px 10px 1px 1px;
}

@import "../../abstracts/variables";
@import "../../base/_base-dir";

.maggiclink {

    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;

    .crPage--inner__content {
        text-align: center;

        p.text-small {
            line-height: 1.4;

            a {
                color: $primary-color;
            }
        }
    }
}
/* Dropdowon top left css starts here */

.drop-butt-trans button,
.drop-butt-trans button:hover,
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  background: none !important;
  color: black !important;
  border: none;
  box-shadow: none;
}

.dropdown-toggle::after {
  border-width: 0 2px 2px 0 !important;
  border: solid black;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.badge.badge-requested {
  background-color: #EAFFEA;
  color: #2A8D34;
}

.badge.badge-introduced {
  background-color: #F2EAFF;
  color: #623BA5;
}

.badge.badge-hired {
  background-color: #EAFBFF;
  color: #009ABE;
}

.cont-info {
  cursor: pointer;
}

.sticky-header {
  position: sticky;
  top: 0;
}

.switch-back-float {
  position: fixed;
  bottom: 2.9375rem;
  left: 2.9375rem;
}


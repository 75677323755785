@import "../../abstracts/mixins";


.buttonActions {
  @include flexbox;
  @include align-items-center;

  .solidBtn {
      width: auto;
      font-size: 0.9rem;
      padding: 0px 20px;
  }

  .btn-outline-primary {
      width: 155px;
     /// height: 46px;
      padding: 0px 36px;
      border-color: #eeeeee;
      border-width: 2px;
      color: $black;
      border-radius: 8px;

      &:hover {
          background-color: transparent;
          color: $black;
      }
  }
}

.back-link {
  color: #333;
}

.md-w-40 {
  border-radius: 8px;
  width: 135px !important;
  max-width: 135px !important;
}

.buttonActions .btn-outline-primary,
.buttonActions .solidBtn {
  //height: 46px;
  padding: 12px 6px; line-height: 1;
}
.widthAuto  {
  .modal-dialog {
      width: auto!important;
  }
}
.customModal--w500 {
  .modal-body {
      padding: 1rem 2rem;
  }

  .modal-dialog {
      width: 650px;
      max-width: 100%;

      textarea.form-control {
          height: 120px !important;
      }

      .modal-title {
          padding-bottom: 10px;

          span {
              opacity: 0.6;
              padding-right: 5px;
          }
      }
  }
}

.btn-primary:hover {
  color: #fff;
  background-color: $primary-color;
  opacity: 0.6;
}

.form-control:focus {
  border-color: #73238500;
  box-shadow: 0 0 0 0.2rem $primary-color;
}

@import '../../abstracts/variables';

body .tags {
  .checkbox {
    padding: 0 !important;
  }

  .form-check {
    width: 100%;
    margin-right: 0;

    label {
      width: 100%;
      padding: 15px 36px;
    }

    .checkmark {
      top: 13px;
      left: 8px;
    }
  }
}

@font-face {
  font-family: 'icomoon';
  src: url('../../../fonts/icomoon.eot?7js8ro');
  src: url('../../../fonts/icomoon.eot?7js8ro#iefix') format('embedded-opentype'),
    url('../../../fonts/icomoon.woff2?7js8ro') format('woff2'),
    url('../../../fonts/icomoon.ttf?7js8ro') format('truetype'),
    url('../../../fonts/icomoon.woff?7js8ro') format('woff'),
    url('../../../fonts/icomoon.svg?7js8ro#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.bg-light {
  background: linear-gradient(90deg,
      rgba(72, 161, 199, 1) 0%,
      rgba(156, 211, 195, 1) 63%,
      rgba(175, 222, 194, 1) 100%);
  background-color: transparent !important;

  &.navbar a {
    padding-left: 25px;
  }
}

.container.CustomWidth .noteText {
  label {
    width: 148px !important;
  }

  span {
    white-space: normal;
    text-align: justify;
  }
}

.container.CustomWidth {
  .card-header {
    padding: 0px 0px 35px;

    // padding-left: 0px!important;
    // padding-bottom: 20px;
    .pageTitle {
      font-size: 18px;
      color: $midgrey;
      letter-spacing: -0.1px;
    }
  }

  form {
    .form-group {
      label {
        margin-bottom: 0.5rem;
        letter-spacing: 1px;
        color: $labelColor;
        font-size: 0.8rem;
        text-transform: capitalize;
      }
    }
  }

  .card {
    border: 0;

    .table {
      thead {
        tr {
          th {
            font-size: 14px;
            letter-spacing: 1px;
            color: $labelColor;
            letter-spacing: 1px;
          }
        }
      }

      tbody {
        tr {
          &:hover {
            // background: #FAF9FA;
            cursor: pointer;
          }

          td {
            font-size: 14px;
            color: $midgrey;

            .inactivedot {
              width: 10px;
              height: 10px;
              border-radius: 1000px;
              background: #ff0000;
              margin-left: 21px;
            }

            &:nth-first-child(1) {
              text-transform: capitalize;
            }
          }
        }
      }

      tr {
        .actions {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          span {
            margin-right: 5px;
          }

          svg {
            opacity: 0;
            visibility: hidden;
            -moz-transition: all ease-in-out 350ms;
            -o-transition: all ease-in-out 350ms;
            -webkit-transition: all ease-in-out 350ms;
            transition: all ease-in-out 350ms;
            margin-right: 5px;
          }
        }

        &:hover {
          .actions {
            svg {
              opacity: 1;
              visibility: visible;
              -moz-transition: all ease-in-out 350ms;
              -o-transition: all ease-in-out 350ms;
              -webkit-transition: all ease-in-out 350ms;
              transition: all ease-in-out 350ms;
            }
          }
        }

        td {
          &:nth-last-child(1) {
            // display: flex;
            // align-items: center;
            // justify-content: flex-end;
          }

          span {
            margin-left: 12px;

            img {
              height: 20px;
            }
          }
        }
      }

      &.table-bordered {
        tr {
          border-bottom: 1px solid $borderColor;

          .inActive {
            width: 10px;
            height: 10px;
            background: red;
            border-radius: 50px;
          }
        }

        td,
        th {
          border: 0px solid $borderColor;
          /*padding: 1.2rem 0.7rem;*/
          vertical-align: middle;
          line-height: initial;
          white-space: break-spaces;
          // word-break: break-all;
        }

        th {
          font-weight: 500;
        }
      }
    }

    .card-header {
      background-color: transparent;
      border-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.card-header__align {
        background-color: transparent;
        border-bottom: 0;
        display: flex;
        align-items: center;
        // flex-direction: row-reverse;
        justify-content: space-between;

        .commonBtn {
          .solidBtn {
            padding: 0px 1rem;
            font-size: 0.9rem;
            width: 155px;
          }
        }
      }

      //Variables
      .input-icon {
        position: relative;

        &:focus {
          border: 1.5px solid $primary-color !important;
        }

        span.input-icon-addon {
          position: absolute;
          left: 24px;
          top: 5px;
          bottom: 0;
          margin: auto;
        }

        input {
          max-height: 37px;
          background: transparent;
          border: 0;
          font-size: 15px;
          color: $midgrey !important;
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
          border: 1.5px solid #dfe3e8;
          padding: 0px 10px 0px 35px;
          border-radius: 2px;
          align-items: baseline;
          height: 37px;
          position: relative;
        }
      }
    }

    .card-footer {
      padding: 0.75rem 0rem;
      border-top: 0;
      align-items: center;
      justify-content: space-between;
      position: sticky;
      bottom: 0;
      background-color: red;
      // width: 100%;
    }
  }
}

.fixedmail {
  font-size: 12px !important;
  border: 1px solid #dfe3ea;
  background: #f3f3f3;
  padding: 4px 10px;
  border-radius: 3px;
}

.fontS12 {
  font-size: 16px;
}

.last_login {
  text-align: left;
}

table#dataTable thead tr th {
  cursor: pointer;

  &:hover i {
    display: block !important;
  }

  i {
    display: none !important;
  }

  .sortWrap--up {
    i {
      display: block !important;
    }
  }
}

.card-footer {
  padding: 0.75rem 1rem;
  border-top: 0;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  background-color: #fff;
    width: 100%;
}
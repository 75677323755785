.saleforce {
    .react-tabs__tab-list {
        margin-bottom: 10px;
    }

    .card {
        .table {
            tr {
                td {
                    &:nth-last-child(1) {
                        justify-content: initial !important;
                    }
                }
            }
        }
    }
}

.modal {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .modal-center {
        .modal-content {

            align-items: center;
        }
    }
}

// security table scss
.security {
    table {
        .react-switch-handle {
            height: 10px !important;
            width: 10px !important;
            top: 6px !important;
            left: 5px;

        }

        .react-switch-bg {
            height: 22px !important;
            width: 50px !important;
            border: 1.5px solid #3d3d3d !important;

        }

        tbody {
            tr {
                &:nth-child(1) {
                    border-top: 1px solid #dee2e6;
                }
            }
        }
    }
}

.nonClickable {
    table {
        tr {
            &:hover {
                cursor: auto;
            }
        }
    }
}

.react-tabs__tab-list {
    padding-left: 10px;
}

.react-tabs__tab-list button {
    margin-right: 10px;
}


@media(max-width: 480px) {
    .table td.toggle-btn {
        justify-content: left !important;
    }
}
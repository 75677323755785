.imageUploadWrap {
    .cropWrap {
        .removeImage {
            position: absolute;
            top: 20px;
            right: 20px;
            color: #fff;
            z-index: 9;
            cursor: pointer;
        }
        width: 100%;
        max-width: 350px;
        margin: 0 auto 1rem;
        position: relative;

        .cropWrap--image {
            margin-bottom: 0;
        }

        .cropWrap--image {
            width: 100%;
            height: 350px;
            margin: 0 auto 1.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #f4f5f7;
            position: relative;
        }
    }

    .file-W-auto {
        max-width: 350px;
        margin: 0 auto 1rem;
        height: 350px;
        position: relative;
        overflow: hidden;

        .dragBrowse .dropFile.icon-upload {
            font-weight: 600;
            margin-right: 0.3rem;
        }

        .Img-Browse-btn {
            margin: 0 !important;
            width: auto !important;

            .Choose-text-Hid {
                width: auto;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                .browse-Up-btn {
                    margin-bottom: 0;
                    height: auto;
                    position: static;
                    cursor: pointer;
                    font-weight: 500;
                    font-size: 13.92px;
                    font-size: 0.87rem;
                }
            }
        }

        .dragBrowse {
            .dropFile {
                padding: 0.5rem 0.2rem;
                color:$labelColor;

                &.icon-upload {
                    font-weight: 600;
                    margin-right: 0.3rem;
                }
            }

            .btn-Upload-Image {
                margin: 1rem auto;
                width: 100%;
                max-width: 350px;
            }

            .DragDrop {
                height: 350px;
                background-color: transparent;
                padding: 0.5rem 1rem;
                color: #212b36;
                border: 2px dashed #dfe3e8;
                text-align: center;
            }

        }


    }
}

.discModal {
    h5 {
        font-size: 1.62rem;
        line-height: initial;
    }

    p {
        line-height: 1.3;
        font-size: 0.94rem;
    }
}

.rangeslider {
    &.rangeslider-horizontal {
        height: 6px;
    }
        .rangeslider__handle {
            background: transparent;
            border: 0;
            width: 15px;
            cursor: pointer;
            display: inline-block;
            position: absolute;
            box-shadow: none;

            &:after {               
                top: 7px;     
                left: 0;          
                background-color:$primary-color;              
            }           
        }
      .rangeslider__fill {            
            background-color:$primary-color;          
        }
    
}
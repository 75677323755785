@import "../../abstracts/variables";

.ForgotPass {
  max-width: 100%;
  width: 420px;
  margin: auto;

  .solidBtn-hollow__blue {
    border: 1px solid $lightGrey !important;
  }

  .btn-link {
    text-align: center !important;
    font-weight: 400;
    span {
      color: $primary-color;
    }
  }
}
/*checkbox css*/
.check-container,
.radio-container {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: $font-family;

  label {
    padding-left: 2rem;
    margin-bottom: 0;
    position: relative;
    cursor: pointer;
    text-transform: none;
    font-size: 14px;
    color: $labelColor;
    min-height: 15px;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 0;
    margin: 0;

    &.disabled,
    &:disabled {
      cursor: not-allowed;
    }
  }

  .checkmark {
    position: absolute;
    top: -3px;
    left: 0;
    width: 20px;
    height: 20px;
    border: 2px solid $primary-color;
    background-color: $transparent;


    &:after {
      content: "";
      visibility: hidden;
      opacity: 0;

    }
  }

  input.disabled~.checkmark,
  input:checked:disabled~.checkmark,
  input:disabled~.checkmark {
    background-color: $borderColor;
    border-color: $borderColor;
    color: $borderColor;
    cursor: not-allowed;

    label {
      color: #000000;
      font-weight: 500;
    }
  }

  input.disabled,
  input:disabled~span.pl-2 {
    cursor: not-allowed;
  }
}

.check-container {
  .checkmark {
    border-radius: 3px;

    &:after {
      position: absolute;
      left: 6px;
      top: 4px;
      width: 5px;
      height: 8px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  input:checked~.checkmark {
    background-color: $primary-color;
    border-color: $primary-color;
    @include box-shadow($top:0, $left:1px, $blur:1px, $color:rgba(97, 121, 133, 0.05));
    color: lightnen($primary-color, 5%);

    &:after {
      opacity: 1;
      visibility: visible;
    }
  }

  input:checked:disabled~.checkmark {
    border-color: $borderColor;
    color: $borderColor;
    cursor: not-allowed;

    &:after {
      border-color: $borderColor
      ;
    }
  }
}

.radio-container {
  .checkmark {
    border-radius: 50%;

    &:after {
      position: absolute;
      left: 2px;
      top: 2px;
      width: 7px;
      height: 7px;
      background-color: $primary-color;
      border-radius: 50%;
    }
  }

  input:checked~.checkmark {
    border-color: $borderColor;
    @include box-shadow($top:0, $left:1px, $blur:1px, $color:rgba(97, 121, 133, 0.05));
    color: $primary-color;

    &:after {
      opacity: 1;
      visibility: visible;
    }
  }

  input.disabled~.checkmark,
  input:checked:disabled~.checkmark,
  input:disabled~.checkmark {
    &:after {
      background-color: $borderColor;
    }
  }
}

.check-container input:indeterminate~.checkmark {
  background-color: $primary-color;
  border-color: $primary-color;
  @include box-shadow($top:0, $left:1px, $blur:1px, $color:rgba(97, 121, 133, 0.05));
  color: $primary-color;

  &:after {
    border-width: 0 0 2px 0;
    top: 0;
    left: 2px;
    width: 7px;
    height: 7px;
    transform: rotate(0);
    opacity: 1;
    visibility: visible;
  }
}

// added by kinjal for listview checkbox
// input[type=checkbox], input[type=radio]{border-color: $borderColor !important; height: 16px; width: 16px; border-radius: $borderRadius;}
// input[type=checkbox]:checked{ background-color: $primary-color !important;}
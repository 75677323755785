.modal-backdrop {
  z-index: 99999;
}

.modal {
  z-index: 999999;

  .modal-label {
    font-size: 12px;
    color: $labelColor;
    letter-spacing: 1px;
    margin-bottom: 11px;
  }

  label {
    font-size: 14px;
    color: $labelColor;
  }

  input,
  textarea {
    font-size: 14px;
    columns: $labelColor;
  }

  &.thanksModal .crForm--login h2 {
    width: 245px;
  }
}

.alert {
  position: fixed;
  top: 25%;
  width: auto;
  height: auto !important;
  padding: 1rem 3.5rem 1rem 1.5rem;
  z-index: 10;
  display: inline-block;
  MARGIN: auto;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px !important;
}

.modal-content {
  border-radius: 16px;

  h4 {
    font-size: 1.25rem;
  }

  .closebuttonCust {
    position: absolute;
    right: -39px;
    color: #fff;
    background: #2D2D2D;
    padding: 6px 10px;
    border-radius: 50px;
    cursor: pointer;
    height: 30px !important;
    width: 30px !important;
    text-align: center;
  }
}

.customModal--w900 {
  .modal-body {
    padding: 1rem 2rem;
  }

  .modal-dialog {
    width: 900px;
    max-width: 100%;

    textarea.form-control {
      height: 120px !important;
    }

    .modal-title {
      padding-bottom: 10px;

      span {
        opacity: 0.6;
        padding-right: 5px;
      }
    }
  }
}

.customModal--w500 {
  .modal-body {
    padding: 1rem 1.5rem;
  }

  .modal-hdr {
    padding: 0;
    margin: 0;
    justify-content: space-between;
    border-bottom: solid 1px $borderColor;
  }

  .close {
    z-index: 99;
  }

  .modal-dialog {
    width: 650px;
    max-width: 100%;

    textarea.form-control {
      height: 120px !important;
    }

    .modal-title {
      padding: 12px 24px;

      span {
        opacity: 0.6;
        padding-right: 5px;
      }
    }
  }
}

.success-body {
  text-align: center;
  padding: 24px;

  svg {
    height: 64px !important;
    width: 64px !important;
    font-size: 60px;
    color: $primaryTextColor;
    opacity: 0.7;
    margin-bottom: 15px;
  }

  .modal-content h4 {
    line-height: 60px;
  }
}
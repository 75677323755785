@import "../abstracts/variables";

h1 {
  color: $primary-color;
}

.show {
  display: inline-block !important;
}

.btn-link {
  text-transform: inherit;
  font-weight: 500;
  color: $primary-color;

  &.focus,
  &:focus {
    text-decoration: none;
  }

  &:hover {
    color: $primary-color;
    opacity: 0.8;
  }
}

.btn {

  &.focus,
  &:focus {
    outline: 0;
    box-shadow: none;
  }
}

.error {
  width: 100%;
  margin-top: 10px;
  font-size: 12px;
  color: red;
}

.siteLoader.rdLoader {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: rgba(255, 255, 255, 0.7);
  margin: auto;
  align-items: center;
  justify-content: center;

  .rdLoader__inner {
    height: auto !important;
  }

}

.alert {
  top: 54px !important;
}
@import "../../abstracts/mixins";
.fSecWrap {
  position: static;
  padding: 0;
  overflow: hidden;
  display: flex;
  align-items: stretch;
}
.contactLftImg{
  width: 400px;
  min-height: 100vh;
  background-image: url("../../../images/formBgImg.jpg");
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: 0 0;
  background-attachment: fixed;
  background-size: contain;
}
.sldFormWrap{
  width: calc( 100% - 400px);
  padding: 0 20px;
  background-color: #fff;
}
.formHeader{
  width: 100%;
  padding: 35px 0;
  margin: 0;
  background-color: #fff;
  position: relative;
  z-index: 9;
  &.updateformHeader{
    padding: 50px 0 !important;
  }
  img.form_logo {
    max-width: 300px;
  }
  .headInner{
    width: 100%;
    max-width: 610px;
    padding: 0 5px;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
  }
  &--fixed{
    .headInner{
      padding-top: 20px;
      position: fixed;
      margin-left: 198px;
    }
  }
}

.sldForm {
  max-width: 650px;
  padding: 0 20px;
  margin: 0 auto;
}

.inpuBlk {
  padding-top: 30px;
  padding-bottom: 30px;
  height: 100vh;
}

.headText {
  margin-bottom: 20px;
}

.headText h2 {
  text-transform: uppercase;
  font-size: 24px;
  margin-bottom: 0;
}

.inputInner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.inputInner>.form-group>label{
  position: absolute;
  top: 20px;
  left: 0;
  font-size: 22px;
  transition: all ease-in-out 350ms;
  z-index: 1;
}
.inputInner>.form-group.form-group-active>label{
  font-size: 15px !important;
  top: -15px !important;
  z-index: 3;
}

.inputInner strong {
  font-weight: 500;
}

.inputInner label {
  display: block;
  max-width: 100%;
  font-weight: unset;
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  color: #2d2d2d;
}

.inputInner .form-group {
  position: relative;
}

.inputInner .form-control,
.inputInner .css-2b097c-container .css-1bi0jn-control,
.inputInner .css-2b097c-container.status-box .css-79tn2m-control{
  border: none !important;
  outline-width: 0;
  border-bottom: 1px solid #979797 !important;
  width: 100%;
  font-size: 24px;
  color: #000;
  background-color: transparent;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  margin: 5px 0;
  position: relative;
  z-index: 2;
}
.inputInner .css-g1d714-ValueContainer,
.inputInner .css-1hwfws3{
  padding-left: 0;
}
.inputInner .css-2b097c-container .css-1wa3eu0-placeholder{
  opacity: 0;
  display: none;
}
.inputInner .css-2b097c-container .css-1bi0jn-control,
.inputInner .css-2b097c-container.status-box .css-79tn2m-control{
  height: auto !important;
  min-height: 58px !important;
}

.inputInner .form-control:focus,
.inputInner .css-2b097c-container.status-box .css-79tn2m-control{
  box-shadow: none;
  border: none !important;
  border-bottom: 1px solid #F18F3F !important;
}
.inputInner .css-1hdyby0-multiValue{
  background-color: #F18F3F;
}
.inputInner .css-19z9ngh,
.inputInner .css-1uyi5ol{
  color: #fff;
  cursor: pointer;
}
.inputInner .css-19z9ngh{
  font-size: 15px;
}

.inputInner input[type="checkbox"],
.inputInner input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

.btnActions {
  display: flex;
  align-items: center;
}

.inputInner .checkBox {
  position: relative;
}

.inputInner .checkBox .form-check-input {
  top: 6px;
  position: absolute;
}

.inputInner .checkBox label {
  font-size: 16px;
  line-height: 1.4;
  padding-left: 20px;
}

.cntWrap {
  position: absolute;
  top: 5px;
  right: calc(100% + 15px);
}

.cntWrap__inner {
  display: flex;
  align-items: center;
}

.cntWrap__inner .ctCount {
  font-size: 24px;
  padding-right: 8px;
}

.inputInner textarea.form-control {
  overflow-x: hidden;
  overflow-wrap: break-word;
  height: 107px;
  display: block;
  width: 100%;
  font-family: inherit;
  color: rgb(0, 58, 81);
  background-color: transparent;
  font-size: 1rem;
  line-height: 1.8;
  overflow-wrap: break-word;
  resize: none;
  box-shadow: rgba(0, 58, 81, 0.3) 0 1px;
  padding: 0 0 8px;
  border-radius: 0;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  outline: none;
  overflow: hidden;
  transition: box-shadow 0.1s ease-out 0s;
}

img.button_icon {
  margin-bottom: 3px;
}

.erTREo {
  max-width: 100%;
  font-weight: unset;
  font-size: 12px;
  line-height: 16px;
  color: #2d2d2d;
  font-family: sans-serif;
  margin-left: 12px;
}

.submitbtn {
  position: relative;
  font-family: inherit;
  font-weight: 500;
  cursor: pointer;
  transition-duration: 0.1s;
  transition-property: background-color, color, border-color, opacity, box-shadow;
  transition-timing-function: ease-out;
  box-shadow: rgba(0, 0, 0, 0.1) 0 3px 12px 0;
  min-height: 40px;
  background-color: $primary-color;
  color: $whiteColor;
  outline: none;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  margin: 0;
  padding: 6px 14px;
  border-color: transparent;
  border-radius: 4px;
}

@media only screen and (max-width:620px) {
  .upform img.form_logo {
    margin-top: -100px;
  }
}

button.btn.arrow_button {
  text-align: left;
  padding-left: 0;
  margin-left: -47px;
}

button.submitbtn.back_button {
  margin-right: 10px;
}

.butn {
  margin-top: -260px;
  float: right;
}
.sinpuBlk {
    height: 85vh;
}

@media only screen and (max-width: 1200px){
  .contactLftImg{
    width: 300px;
  }
  .sldFormWrap{
    width: auto;
    flex: 1 1 auto;
  }
  .formHeader--fixed .headInner{
    margin-left: 142px;
  }
}

@media only screen and (max-width: 991px){
  .contactLftImg{
    display: none;
  }
  .formHeader--fixed .headInner{
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px){
  .formHeader img.form_logo {
    max-width: 200px;
  }
  .headText h2 {
    font-size: 18px;
  }
  body .fontSize,
  .inputInner > .form-group > label,
  .inputInner .form-control,
  .inputInner .css-2b097c-container .css-1bi0jn-control,
  .inputInner .css-2b097c-container.status-box .css-79tn2m-control {
    font-size: 16px;
  }
  .inputInner > .form-group > label{
    top: 9px;
  }
  .fSecWrap .inputInner .css-2b097c-container .css-1bi0jn-control,
  .fSecWrap .inputInner .css-2b097c-container.status-box .css-79tn2m-control{
    min-height: 46px !important;
  }
}

@media only screen and (max-width: 575px){
  .cntHeader.text-center.mb-4{
    margin-bottom: 0.5rem !important;
  }
  .sldFormWrap{
    padding: 0;
  }
  .inputInner > .form-group > label{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 20px;
  }
}
